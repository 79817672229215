<template>
  <div class="flex header"
       :class="{
                  appSize0:size==0,
                  appSize1:size==1,
                  appSize2:size==2,
               }"
  >

    <span class="burger-menu">
      <div class="burger-z">
        <router-link to="/" class="logo" alt="Safety Management System"><img alt="Safety Management System" src="./assets/logo.png"/></router-link>
        <span class="burger-menu-b" title="Click: freeze left menu">
          <Burger @click="sidebarView=!sidebarView"/>
        </span>
      </div>
      <div class="left-menu"
           :class="{
                  appSize0:size==0,
                  appSize1:size==1,
                  appSize2:size==2,
                  'left-menu-dashboard':leftMenuDesign,
                  'left-menu-show':sidebarView,
               }">
        <template v-if="!leftMenuDesign">
          <router-link to="/mnl" class="link">Manuals</router-link>
          <router-link to="/crt" class="link">Certification</router-link>
          <router-link to="/prs" class="link">Personnel management</router-link>
          <router-link to="/pur" class="link">Purchase</router-link>
          <router-link to="/pln" class="link">Planned maintenance</router-link>
          <router-link to="/emr" class="link">Emergency preparedness<br/>Training and drills</router-link>
          <router-link to="/shp" class="link">Ship operations</router-link>
          <router-link to="/vrf" class="link">System verifications</router-link>
          <router-link to="/rep" class="link">Reporting System<br/>Follow Up Control</router-link>
        </template>
        <template v-else>
          <Dashboard/>
        </template>
        <div class="left-menu__change" @click="leftMenuDesign=!leftMenuDesign" title="Change menu style">
          <Burger v-if="leftMenuDesign"/>
          <svg v-else><use xlink:href="#icon-grid"></use></svg>
        </div>
      </div>
    </span>
    <div class="flex-sp"></div><!--{{headerTitleView}}-->
    <div class="header__title">{{$route.name}}</div>
    <div class="flex-sp"></div>
    <div class="header_sizes" title="Font sizes">
      <label><input v-model="size" value="0" type="radio"><b>A</b></label>
      <label><input v-model="size" value="1" type="radio"><b>A</b></label>
      <label><input v-model="size" value="2" type="radio"><b>A</b></label>
    </div>
    <div class="menu-dd">
      <div class="menu-dd__ico" title="Settings"><svg><use xlink:href="#icon-sets"></use></svg></div>
      <div class="menu-dd__dd">
        <router-link to="/users" class="link">Users</router-link>
        <hr>
        <router-link to="/vessels" class="link">Vessels</router-link>
        <router-link to="/machinery" class="link">Machinery List</router-link>
        <router-link to="/manual-cat" class="link">Manual Categories</router-link>
        <router-link to="/positions" class="link">Positions</router-link>
        <router-link to="/wage" class="link">ITF Wage Scale & COE</router-link>
      </div>
    </div>
    <div class="header_profile" @click="debug">
      Admin
    </div>
  </div>



  <div class="main" :class="{
    sidebarView:sidebarView,
    appSize0:size==0,
    appSize1:size==1,
    appSize2:size==2,
    mainleftmenu:leftMenuDesign,
  }">
    <div class="page">
      <router-view/>
    </div>
    <svg style="display:none">
      <symbol id="icon-book" viewBox="0 0 24 24"><path d="M21,5C19.89,4.65 18.67,4.5 17.5,4.5C15.55,4.5 13.45,4.9 12,6C10.55,4.9 8.45,4.5 6.5,4.5C4.55,4.5 2.45,4.9 1,6V20.65C1,20.9 1.25,21.15 1.5,21.15C1.6,21.15 1.65,21.1 1.75,21.1C3.1,20.45 5.05,20 6.5,20C8.45,20 10.55,20.4 12,21.5C13.35,20.65 15.8,20 17.5,20C19.15,20 20.85,20.3 22.25,21.05C22.35,21.1 22.4,21.1 22.5,21.1C22.75,21.1 23,20.85 23,20.6V6C22.4,5.55 21.75,5.25 21,5M21,18.5C19.9,18.15 18.7,18 17.5,18C15.8,18 13.35,18.65 12,19.5V8C13.35,7.15 15.8,6.5 17.5,6.5C18.7,6.5 19.9,6.65 21,7V18.5Z" /></symbol>
      <symbol id="icon-sert" viewBox="0 0 24 24"><path d="M20.39,19.37L16.38,18L15,22L11.92,16L9,22L7.62,18L3.61,19.37L6.53,13.37C5.57,12.17 5,10.65 5,9A7,7 0 0,1 12,2A7,7 0 0,1 19,9C19,10.65 18.43,12.17 17.47,13.37L20.39,19.37M7,9L9.69,10.34L9.5,13.34L12,11.68L14.5,13.33L14.33,10.34L17,9L14.32,7.65L14.5,4.67L12,6.31L9.5,4.65L9.67,7.66L7,9Z" /></symbol>
      <symbol id="icon-users" viewBox="0 0 24 24"><path d="M2,3H22C23.05,3 24,3.95 24,5V19C24,20.05 23.05,21 22,21H2C0.95,21 0,20.05 0,19V5C0,3.95 0.95,3 2,3M14,6V7H22V6H14M14,8V9H21.5L22,9V8H14M14,10V11H21V10H14M8,13.91C6,13.91 2,15 2,17V18H14V17C14,15 10,13.91 8,13.91M8,6A3,3 0 0,0 5,9A3,3 0 0,0 8,12A3,3 0 0,0 11,9A3,3 0 0,0 8,6Z" /></symbol>
      <symbol id="icon-pays" viewBox="0 0 24 24"><path d="M19 20C19 21.11 18.11 22 17 22C15.89 22 15 21.1 15 20C15 18.89 15.89 18 17 18C18.11 18 19 18.9 19 20M7 18C5.89 18 5 18.89 5 20C5 21.1 5.89 22 7 22C8.11 22 9 21.11 9 20S8.11 18 7 18M7.2 14.63L7.17 14.75C7.17 14.89 7.28 15 7.42 15H19V17H7C5.89 17 5 16.1 5 15C5 14.65 5.09 14.32 5.24 14.04L6.6 11.59L3 4H1V2H4.27L5.21 4H20C20.55 4 21 4.45 21 5C21 5.17 20.95 5.34 20.88 5.5L17.3 11.97C16.96 12.58 16.3 13 15.55 13H8.1L7.2 14.63M8.5 11H10V9H7.56L8.5 11M11 9V11H14V9H11M14 8V6H11V8H14M17.11 9H15V11H16L17.11 9M18.78 6H15V8H17.67L18.78 6M6.14 6L7.08 8H10V6H6.14Z" /></symbol>
      <symbol id="icon-tools" viewBox="0 0 24 24"><path d="M16.06 13.09L21.69 18.68L18.37 21.96L12.78 16.37V15.45L15.14 13.09H16.06M16.97 10.56L16 9.6L11.21 14.4V16.37L5.58 22L2.3 18.68L7.89 13.09H9.86L10.64 12.31L6.8 8.46H5.5L2.69 5.62L5.31 3L8.11 5.8V7.11L12 10.95L14.66 8.29L13.7 7.28L15 5.97H12.34L11.69 5.32L15 2L15.66 2.66V5.32L16.97 4L20.25 7.28C21.34 8.38 21.34 10.17 20.25 11.26L18.28 9.25L16.97 10.56Z" /></symbol>
      <symbol id="icon-bell" viewBox="0 0 24 24"><path d="M16,17H7V10.5C7,8 9,6 11.5,6C14,6 16,8 16,10.5M18,16V10.5C18,7.43 15.86,4.86 13,4.18V3.5A1.5,1.5 0 0,0 11.5,2A1.5,1.5 0 0,0 10,3.5V4.18C7.13,4.86 5,7.43 5,10.5V16L3,18V19H20V18M11.5,22A2,2 0 0,0 13.5,20H9.5A2,2 0 0,0 11.5,22Z" /></symbol>
      <symbol id="icon-tiling" viewBox="0 0 24 24"><path d="M19,5V7H15V5H19M9,5V11H5V5H9M19,13V19H15V13H19M9,17V19H5V17H9M21,3H13V9H21V3M11,3H3V13H11V3M21,11H13V21H21V11M11,15H3V21H11V15Z" /></symbol>
      <symbol id="icon-checks" viewBox="0 0 24 24"><path d="M20,12A8,8 0 0,1 12,20A8,8 0 0,1 4,12A8,8 0 0,1 12,4C12.76,4 13.5,4.11 14.2,4.31L15.77,2.74C14.61,2.26 13.34,2 12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12M7.91,10.08L6.5,11.5L11,16L21,6L19.59,4.58L11,13.17L7.91,10.08Z" /></symbol>
      <symbol id="icon-report" viewBox="0 0 24 24"><path d="M17,21L14.25,18L15.41,16.84L17,18.43L20.59,14.84L21.75,16.25M12.8,21H5C3.89,21 3,20.1 3,19V5C3,3.89 3.89,3 5,3H19A2,2 0 0,1 21,5V12.8C20.12,12.29 19.09,12 18,12L17,12.08V11H7V13H14.69C13.07,14.07 12,15.91 12,18C12,19.09 12.29,20.12 12.8,21M12,15H7V17H12M17,7H7V9H17" /></symbol>

      <symbol id="icon-pdf" viewBox="0 0 24 24"><path d="M19,3A2,2 0 0,1 21,5V19A2,2 0 0,1 19,21H5C3.89,21 3,20.1 3,19V5C3,3.89 3.89,3 5,3H19M10.59,10.08C10.57,10.13 10.3,11.84 8.5,14.77C8.5,14.77 5,16.58 5.83,17.94C6.5,19 8.15,17.9 9.56,15.27C9.56,15.27 11.38,14.63 13.79,14.45C13.79,14.45 17.65,16.19 18.17,14.34C18.69,12.5 15.12,12.9 14.5,13.09C14.5,13.09 12.46,11.75 12,9.89C12,9.89 13.13,5.95 11.38,6C9.63,6.05 10.29,9.12 10.59,10.08M11.4,11.13C11.43,11.13 11.87,12.33 13.29,13.58C13.29,13.58 10.96,14.04 9.9,14.5C9.9,14.5 10.9,12.75 11.4,11.13M15.32,13.84C15.9,13.69 17.64,14 17.58,14.32C17.5,14.65 15.32,13.84 15.32,13.84M8.26,15.7C7.73,16.91 6.83,17.68 6.6,17.67C6.37,17.66 7.3,16.07 8.26,15.7M11.4,8.76C11.39,8.71 11.03,6.57 11.4,6.61C11.94,6.67 11.4,8.71 11.4,8.76Z" /></symbol>
      <symbol id="icon-plus" viewBox="0 0 24 24"><path d="M19,13H13V19H11V13H5V11H11V5H13V11H19V13Z" /></symbol>
      <symbol id="icon-grid" viewBox="0 0 24 24"><path d="M3,11H11V3H3M3,21H11V13H3M13,21H21V13H13M13,3V11H21V3" /></symbol>
      <symbol id="icon-attach" viewBox="0 0 24 24"><path d="M16.5,6V17.5A4,4 0 0,1 12.5,21.5A4,4 0 0,1 8.5,17.5V5A2.5,2.5 0 0,1 11,2.5A2.5,2.5 0 0,1 13.5,5V15.5A1,1 0 0,1 12.5,16.5A1,1 0 0,1 11.5,15.5V6H10V15.5A2.5,2.5 0 0,0 12.5,18A2.5,2.5 0 0,0 15,15.5V5A4,4 0 0,0 11,1A4,4 0 0,0 7,5V17.5A5.5,5.5 0 0,0 12.5,23A5.5,5.5 0 0,0 18,17.5V6H16.5Z" /></symbol>
      <symbol id="icon-sets" viewBox="0 0 24 24"><path d="M12,15.5A3.5,3.5 0 0,1 8.5,12A3.5,3.5 0 0,1 12,8.5A3.5,3.5 0 0,1 15.5,12A3.5,3.5 0 0,1 12,15.5M19.43,12.97C19.47,12.65 19.5,12.33 19.5,12C19.5,11.67 19.47,11.34 19.43,11L21.54,9.37C21.73,9.22 21.78,8.95 21.66,8.73L19.66,5.27C19.54,5.05 19.27,4.96 19.05,5.05L16.56,6.05C16.04,5.66 15.5,5.32 14.87,5.07L14.5,2.42C14.46,2.18 14.25,2 14,2H10C9.75,2 9.54,2.18 9.5,2.42L9.13,5.07C8.5,5.32 7.96,5.66 7.44,6.05L4.95,5.05C4.73,4.96 4.46,5.05 4.34,5.27L2.34,8.73C2.21,8.95 2.27,9.22 2.46,9.37L4.57,11C4.53,11.34 4.5,11.67 4.5,12C4.5,12.33 4.53,12.65 4.57,12.97L2.46,14.63C2.27,14.78 2.21,15.05 2.34,15.27L4.34,18.73C4.46,18.95 4.73,19.03 4.95,18.95L7.44,17.94C7.96,18.34 8.5,18.68 9.13,18.93L9.5,21.58C9.54,21.82 9.75,22 10,22H14C14.25,22 14.46,21.82 14.5,21.58L14.87,18.93C15.5,18.67 16.04,18.34 16.56,17.94L19.05,18.95C19.27,19.03 19.54,18.95 19.66,18.73L21.66,15.27C21.78,15.05 21.73,14.78 21.54,14.63L19.43,12.97Z" /></symbol>
      <symbol id="icon-drag" viewBox="0 0 24 24"><path d="M7,19V17H9V19H7M11,19V17H13V19H11M15,19V17H17V19H15M7,15V13H9V15H7M11,15V13H13V15H11M15,15V13H17V15H15M7,11V9H9V11H7M11,11V9H13V11H11M15,11V9H17V11H15M7,7V5H9V7H7M11,7V5H13V7H11M15,7V5H17V7H15Z" /></symbol>
      <symbol id="icon-del" viewBox="0 0 24 24"><path d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" /></symbol>
      <symbol id="icon-edit" viewBox="0 0 24 24"><path d="M20.71,7.04C21.1,6.65 21.1,6 20.71,5.63L18.37,3.29C18,2.9 17.35,2.9 16.96,3.29L15.12,5.12L18.87,8.87M3,17.25V21H6.75L17.81,9.93L14.06,6.18L3,17.25Z" /></symbol>
      <symbol id="icon-close" viewBox="0 0 24 24"><path d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" /></symbol>
      <symbol id="icon-fullwind" viewBox="0 0 24 24"><path d="M4,6V19H20V6H4M18,17H6V8H18V17Z" /></symbol>
      <symbol id="icon-sort" viewBox="0 0 24 24"><path d="M9,3L5,7H8V14H10V7H13M16,17V10H14V17H11L15,21L19,17H16Z" /></symbol>

      <symbol id="icon-view-vert" viewBox="0 0 24 23"><path d="M1,4h9V3H1ZM1,2h9V1H1ZM1,6h9V5H1ZM1,8h9V7H1Zm0,2h9V9H1Zm0,2h9V11H1Zm0,2h9V13H1Zm0,2h9V15H1Zm0,2h9V17H1Zm0,4h9V21H1Zm0-2h9V19H1ZM12,1V22H23V1ZM22,21H13V5h9Z"/></symbol>
      <symbol id="icon-view-horiz" viewBox="0 0 24 23"><path d="M1,1V2H23V1ZM1,4H23V3H1ZM1,6H23V5H1ZM1,8H23V7H1ZM1,22H23V10H1Zm1-9H22v8H2Z"/></symbol>
      <symbol id="icon-view-wind" viewBox="0 0 24 23"><path d="M1,1V2H23V1ZM7,3H1V4H7ZM7,5H1V6H7ZM7,7H1V8H7ZM7,9H1v1H7Zm0,2H1v1H7Zm0,2H1v1H7Zm0,2H1v1H7Zm0,2H1v1H7ZM20,4h3V3H20Zm0,2h3V5H20Zm0,2h3V7H20Zm0,2h3V9H20Zm0,2h3V11H20Zm0,2h3V13H20Zm0,2h3V15H20Zm0,2h3V17H20ZM7,19H1v1H7Zm13,1h3V19H20ZM1,22H23V21H1ZM8,4V19H19V4ZM18,18H9V7h9Z"/></symbol>
    </svg>
  </div>
</template>

<script>
import Burger    from "./components/ui/Burger";
import Dashboard from "@/views/Dashboard";

export default {
  name: 'SMS-APP',
  components: { Dashboard, Burger},
  mounted() {
    this.$store.dispatch('RELOAD');
  },
  // components: {}
  data:()=>({
    sidebarView:false,
    size:1,
    leftMenuDesign:true,
  }),
  methods:{
    debug(){
      // eslint-disable-next-line no-debugger
      debugger;
    },
  },
}
</script>

<style lang="stylus">

$Opt=json('../../../../_Global/Stylus/MyBootstrap/Sets1.json5', { hash: true })

@import "../../../../_Global/Stylus/MyBootstrap/FormFields.styl"

FormFields($Opt)

.loading{
  progress_bg(rgba(0,0,0,.05),rgba(255,255,255,.05));
}


html{width: 100%;height: 100%;padding:0;margin:0;}
body{padding:0;margin:0;font:16px/1.56 Arial, Helvetica, Arial, sans-serif;width: 100%;height: 100%;overflow:auto}
.appSize0{font-size:12px}
.appSize1{font-size:16px}
.appSize2{font-size:20px}

.header__title{font:bold 1.2em Arial;}
.header_sizes{
  margin-right:20px;

  border-radius:6px;
  border:1px solid #eee;
  display:flex;align-items: stretch;
  vertical-align:middle;
  label{ flex:1 0 auto; vertical-align:middle; padding:0; margin:0;cursor:pointer;}
  &>*:nth-child(1) b{font-size:12px;border-radius: 6px 0 0 6px;}
  &>*:nth-child(2) b{font-size:16px}
  &>*:nth-child(3) b{font-size:20px;border-radius:0 6px 6px 0;}
  b{display:block;font-weight:normal;line-height:31px;height:31px;padding:0 10px;vertical-align:middle}
  input{display:none;}
  input:checked+b{background:#ffeba3}
}
#app {
  -webkit-font-smoothing antialiased
  -moz-osx-font-smoothing grayscale
  text-align center
  color #2c3e50
  height:100%;
  max-width:100%;
  display: flex;
  flex-direction: column;
}
.flex{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  align-items: center;
  &-left{justify-content: flex-start;}
  &-sp{flex:1 0 auto;}
  &-bottom-border{align-items: flex-end;}
  &-bottom{align-content: flex-end;}
  &-wrap{ flex-wrap: wrap;}
  &-top-border{align-content: flex-start;}
  &-top{align-items: flex-start;}
}
.header{border-bottom:1px solid #ccc;position:fixed;top:0;left:0;right:0;z-index:1000;background:#fff;height:45px;}
.header_profile{
  padding:6px 6px;
}
.logo{
  display:inline-block;
  padding: 0 12px;margin:0;
  img{display:block;}
}


.left-menu{width:220px;background:#f4f4f4;position:fixed;top:46px;left:0;bottom:0;z-index:1000;
  display:none;
  box-shadow:0 10px  10px 0 rgba(0,0,0,0.4)
  a{display:block;padding:12px;text-align:left;text-decoration:none;color:#333;
    &:hover{background:#ccc;color:#000;}
    &.router-link-active{background:#ccc;color:#000;border-left:4px solid #007bff;}
  }
  &__change{position:absolute;bottom:0;left:0;padding:6px;cursor:pointer;
    width:24px;height:24px;
    svg{width:24px;height:24px;}
    &:hover{background:#fff;}
  }
  &-dashboard{
    width:80vw;
    max-width:660px;
    text-align: center
  }
  .MainMenu__li{font-size:1em;margin:0.25rem;}
}


.main{
  FullHeight();
  height:100%;
  position:relative;
  padding:46px 0 0 0px;
  transition: all ease 0.6s;
}
.main.sidebarView{padding-left:220px;}
.main.mainleftmenu.sidebarView{padding-left:660px;}

.appSize0 .left-menu-dashboard{ max-width:560px;}
.appSize0.main.mainleftmenu.sidebarView { padding-left:560px;}
.appSize2 .left-menu-dashboard{ max-width:800px;}
.appSize2.main.mainleftmenu.sidebarView { padding-left:800px;}

.page{
  background:#fff;
  text-align:left;
  white-space:normal;
  overflow:auto;
  position:relative;
  FullHeight();
  h1{padding:0;margin:0;border-bottom:2px solid #ccc;display:inline-block;}
}

.burger-menu{display:inline-block;height:24px;}
.burger-menu-b{display:inline-block;width:24px;height:47px;}

.burger-menu:hover .left-menu,.left-menu.left-menu-show{display:block;}
.burger-z{vertical-align:middle;}

.menu-dd{
  position:relative;
  &__ico{width:24px;height:24px;padding:10px 12px;
    svg{width:24px;height:24px;fill:#999;}
  }
  &__dd{
    background:#fff;
    box-shadow:0 10px 10px 0 rgba(0,0,0,0.4);
    width:200px;
    position:absolute;
    top:100%;
    right:0;
    text-align:left;
    border:1px solid #eee;
    display:none;
    .link{display:block;padding:6px 12px;text-decoration: none;color:#444;
      &:hover{background:#eee;color:#000;}
    }
  }
  &:hover \&__ico{background:#eee;svg{fill:#000;}}
  &:hover \&__dd{display:block;}
}
hr{height:1px;border:0;background:#eee;}





.DragHand{
  cursor: move;
  background:
          radial-gradient(rgba(0,0,0,.3) 1px, transparent 1px) left           top/3px 3px,
          radial-gradient(rgba(0,0,0,.0) 1px, transparent 1px) left -1px top -1px/3px 3px;
  &:hover{
    background:
            radial-gradient(rgba(0,0,0,.8) 1px, transparent 1px) left           top/3px 3px,
            radial-gradient(rgba(0,0,0,.0) 1px, transparent 1px) left -1px top -1px/3px 3px;
  }
}
</style>
