<template>
  <Layouts2side lvl1="/prs" width="70%">
    <div style="margin:0 auto">
      <button class="btn-add" @click="addGroup()">
        <span class="btn-icon"><svg><use xlink:href="#icon-plus"></use></svg></span>
        <span class="btn-text">Add</span>
      </button>
      <span class="EmployeeBl__vessels">Vessels</span>
      <drop-down hover-ref=".EmployeeBl__vessels" width="150">
        <div class="EmployeeBl__vessels_bl">
<!--          <p>Vessels</p>-->
          <label v-for="v in purchase.vessels" :key="v.id" ><input v-model="purchase.sel_vessels" type="checkbox" :value="v.id"/> {{v.name}}</label>
          <p>Others</p>
          <label><input v-model="filters_reserve" type="checkbox"/> Reserve</label>
          <label><input v-model="filters_arhive" type="checkbox"/> Arсhive</label>
        </div>
      </drop-down>
<!--      <label class="EmployeeBl__label_menu"><input v-model="filters_reserve" type="checkbox"/> Reserve</label>-->
<!--      <label class="EmployeeBl__label_menu"><input v-model="filters_arhive" type="checkbox"/> Arсhive</label>-->
      <span class="EmployeeBl__sort">
        <span class="EmployeeBl__sort-ico"><svg><use xlink:href="#icon-sort"></use></svg></span>
        {{sort_lists[sort_list][1]}}
      </span>
      <drop-down hover-ref=".EmployeeBl__sort" width="150">
        <div class="EmployeeBl__vessels_bl">
          <label v-for="v in sort_lists" :key="'sl'+v[0]" ><input v-model="sort_list" type="radio" :value="v[0]"/> {{v[1]}}</label>
        </div>
      </drop-down>
<!--      <label><input v-model="editEmployee" type="checkbox"/> Edit</label>-->
      <div class="EmployeeBl__list">
        <transition-group name="list">
          <div v-for="u in listFilter" :key="u.id" class="EmployeeBl__list-item" @click="selectUser(u.id)"
               :class="
                (u.id==$route.params.id?'EmployeeBl__list-item-sel':'')+
                (' EmployeeBl__list-item-status'+u.status)
                ">
            <div class="EmployeeBl__photo">
              <div class="EmployeeBl__info">
                <div class="EmployeeBl__info-name">{{u.name}} {{u.surname}}</div>
                <div class="EmployeeBl__info-pos">{{u.EmployeePosition}}</div>
              </div>
              <div :class="'EmployeeBl__info-status EmployeeBl__info-status'+u.status">{{u.EmployeeStatus}}</div>
            </div>
          </div>
        </transition-group>
      </div>

<!--      <table cellspacing="0" align="center" class="left_tbl">
        <tr><th>Position</th><th>Name</th><th>Stasuses</th></tr>
        <tr v-for="u in purchase.employees" :key="u.id" class="employee" @click="selectUser(u.id)"
            :class="{
              tr_sel: u.id==$route.params.id
            }"
        >
          &lt;!&ndash;              <td><span class="btn-icon" @click="EditGroup(u.id)" title="Edit User"><svg><use xlink:href="#icon-edit"></use></svg></span></td>&ndash;&gt;
          <td>{{EmployeePosition(u.position_id)}}</td>
          <td title="Login">{{u.name}} {{u.surname}}</td>
          &lt;!&ndash;              <td title="E-mail">{{u.email}}</td>&ndash;&gt;
          <td>{{EmployeeStatus(u.status)}}</td>
        </tr>
      </table>
      -->
    </div>


    <template #wind>
      <!--      id:1,
      <div class="user_sets" v-if="$route.params.id!==undefined">-->
      <div class="user_sets">
        <div class="flex">
          <div class="flex-sp"><Btn color="yellow" size="sm" :disabled="userChanged" @click="SaveIt()">Save</Btn></div>
        </div>
        <datalist id="list-pas_country">
          <option value="India" ></option>
          <option value="Latvia" ></option>
          <option value="Lithuania" ></option>
          <option value="Norway" ></option>
          <option value="Poland" ></option>
          <option value="Romania" ></option>
          <option value="Russia" ></option>
          <option value="Ukraine" ></option>
        </datalist>
        <div class="flex flex-wrap flex-top flex-left">
          <div class="EmployeeBl">
            <div class="EmployeeBl--title">PERSONAL INFORMATION</div>
            <table style="max-width: 300px">
              <tr>
                <td><label><!--<div class="label_top">name:</div>--><input v-model="name" placeholder="First name"/></label></td>
                <td><label><!--<div class="label_top">surname:</div>--><input v-model="surname" placeholder="Surname"/></label></td>
              </tr>
              <tr>
                <td><label><div class="label_top">Date of birth:</div><input v-model="birth" type="date" placeholder="Date of birth"/></label></td>
                <td><label><div class="label_top">Place of birth:</div><input v-model="birthplace" placeholder="Place of birth"/></label></td>
              </tr>
              <tr>
                <td><label><div class="label_top">Nationality:</div><input v-model="nationality"  placeholder="Nationality"/></label></td>
                <td><label><div class="label_top">Marital Status:</div><select v-model="marital"><option value="Single">Single</option><option value="Married" selected="">Married</option><option value="Divorced">Divorced</option><option value="Widower">Widower</option></select></label></td>
              </tr>
              <tr>
                <td colspan="2"><label><div class="label_top">Number of children under age 21:</div><input v-model="childs21" type="number"/></label></td>
              </tr>
            </table>
          </div>
          <div class="EmployeeBl">
            <div class="EmployeeBl--title">CIVIL / MARITIME ID</div>
            <table style="max-width: 550px">
              <tr><th colspan="9">Travel Passport</th></tr>
              <tr>
                <td><label><div class="label_top">Country:</div><input v-model="pas_country" list="list-pas_country" placeholder="Country..."/></label></td>
                <td><label><div class="label_top">Number:</div><input v-model="pas_number" placeholder="Number..."/></label></td>
                <td><label><div class="label_top">Issued:</div><input v-model="pas_issued" type="date"/></label></td>
                <td><label><div class="label_top">Expiry:</div><input v-model="pas_expirity" type="date"/></label></td>
              </tr>
              <tr><th colspan="9">National Seaman's Book <label style="display:inline-block"><input v-model="sea_is" type="checkbox" :value="1"/> No expiry</label></th></tr>
              <tr>
                <td><label><input v-model="sea_country" list="list-pas_country" placeholder="Country..."/></label></td>
                <td><label><input v-model="sea_number" placeholder="Number..."/></label></td>
                <td><label><input v-model="sea_issued" placeholder="..." type="date"/></label></td>
                <td><label v-if="sea_is"><input v-model="sea_expirity" placeholder="..." type="date"/></label></td>
              </tr>
              <tr v-if="sea_country==='Russia'||sea_country==='Russian'"><th colspan="9">Seaman's ID (Russia)</th></tr>
              <tr v-if="sea_country==='Russia'||sea_country==='Russian'">
                <td></td>
                <td><label><input v-model="rus_number" placeholder="Number..."/></label></td>
                <td><label><input v-model="rus_issued" placeholder="..." type="date"/></label></td>
                <td><label><input v-model="rus_expirity" placeholder="..." type="date"/></label></td>
              </tr>
              <tr><th colspan="9">Flag State Seaman's Book</th></tr>
              <tr>
                <td><label><input v-model="dop_country" list="list-pas_country" placeholder="Country..."/></label></td>
                <td><label><input v-model="dop_number" placeholder="Number..."/></label></td>
                <td><label><input v-model="dop_issued" type="date"/></label></td>
                <td><label><input v-model="dop_expirity" type="date"/></label></td>
              </tr>
              <tr><th colspan="9">Schengen</th></tr>
              <tr>
                <td colspan="2"><label><input v-model="shengen" type="checkbox"/> Schengen</label></td>
                <td colspan="2"><label v-if="!shengen"><input v-model="shengen_is" type="checkbox"/> Schengen visa valid till</label></td>
              </tr>
            </table>
            <table v-if="!shengen && shengen_is" style="max-width: 550px">
              <tr>
                <td><label><input v-model="shengen_country" list="list-pas_country" placeholder="Country..."/></label></td>
                <td><label><input v-model="shengen_number" placeholder="Number..."/></label></td>
                <td><label><input v-model="shengen_expirity" type="date"/></label></td>
              </tr>
            </table>
          </div>
          <div class="EmployeeBl">
            <div class="EmployeeBl--title">CONTACT INFORMATION</div>
            <table style="max-width: 300px">
              <tr>
                <td colspan="2"><label><div class="label_top">Street address:</div><input v-model="street" placeholder="..."/></label></td>
              </tr>
              <tr>
                <td><label><div class="label_top">City:</div><input v-model="city" placeholder="..."/></label></td>
                <td><label><div class="label_top">Post code:</div><input v-model="post_code" placeholder="..."/></label></td>
              </tr>
              <tr>
                <td><label><div class="label_top">Country:</div><input v-model="country"  placeholder="..."/></label></td>
                <td><label><div class="label_top">Phone (home):</div><input v-model="phone"  placeholder="..."/></label></td>
              </tr>
              <tr>
                <td><label><div class="label_top">Email:</div><input v-model="email"  placeholder="..."/></label></td>
                <td><label><div class="label_top">Phone (mob):</div><input v-model="phonem"  placeholder="..."/></label></td>
              </tr>
              <tr>
                <td><label><div class="label_top">Telegram:</div><input v-model="t" placeholder="..."/></label></td>
                <td><label><div class="label_top">WhatsApp:</div><input v-model="w"  placeholder="..."/></label></td>
              </tr>
              <tr>
                <td><label><div class="label_top">Viber:</div><input v-model="v" placeholder="..."/></label></td>
                <td></td>
              </tr>
            </table>
          </div>
          <div class="EmployeeBl">
            <div class="EmployeeBl--title">NEXT OF KIN</div>
            <table style="max-width: 320px">
              <tr>
                <td><label><div class="label_top">First name:</div><input v-model="nok_name" placeholder="First name"/></label></td>
                <td><label><div class="label_top">Surname:</div><input v-model="nok_surname" placeholder="Surname"/></label></td>
              </tr>
              <tr>
                <td colspan="2"><label><div class="label_top">Relashionship:</div>
                  <select v-model="nok_relationship"><option value="Wife" selected="">Wife</option><option value="Son">Son</option><option value="Daughter">Daughter</option><option value="Mother">Mother</option><option value="Father">Father</option><option value="Brother">Brother</option><option value="Sister">Sister</option><option value="Grandmother">Grandmother</option><option value="Grandfather">Grandfather</option><option value="Other">Other</option></select>
                </label></td>
              </tr>
              <tr>
                <td colspan="2"><label><div class="label_top">Street address:</div><input v-model="nok_street" placeholder="..."/></label></td>
              </tr>
              <tr>
                <td><label><div class="label_top">City:</div><input v-model="nok_city" placeholder="..."/></label></td>
                <td><label><div class="label_top">Post code:</div><input v-model="nok_post_code" placeholder="..."/></label></td>
              </tr>
              <tr>
                <td><label><div class="label_top">Country:</div><input v-model="nok_country"  placeholder="..."/></label></td>
                <td><label><div class="label_top">Phone (home):</div><input v-model="nok_phone"  placeholder="..."/></label></td>
              </tr>
              <tr>
                <td><label><div class="label_top">Email:</div><input v-model="nok_email"  placeholder="..."/></label></td>
                <td><label><div class="label_top">Phone (mob):</div><input v-model="nok_phonem"  placeholder="..."/></label></td>
              </tr>
            </table>
  <!--
            <label><div class="label_top">position_id:</div><input v-model="position_id"/></label>
            <label><div class="label_top">photo_file:</div><input v-model="photo_file"/></label>

            <label><div class="label_top">status:</div><input v-model="status"/></label>

            <label><div class="label_top">dop_name:</div><input v-model="dop_name"/></label>-->



          </div>
        </div>
      </div>
    </template>
  </Layouts2side>
</template>

<script>

import { mapState/*, mapActions*/ } from 'vuex';
// import {VueDraggableNext} from 'vue-draggable-next'
import SuperSort                    from "mylib/Array/SuperSort";
import Layouts2side                 from "@/views/Layouts/Layouts2side";
import Btn                          from "@/components/ui/Btn";
import DropDown                     from "@/components/ui/DropDownClassSelector";
// import SelectDot from "@/components/ui/SelectDot";
// import Btn from "@/components/ui/Btn"; // vue 3

export default {
  name: 'PersonnelManagement',
  components: {
    DropDown,
    Btn,
    Layouts2side,
    // draggable:VueDraggableNext,
  },
  mounted() {
    if(this.$route.params.id!==undefined){
      this.selectUser(this.$route.params.id);
    }
    if(this.$route.params.ag!==undefined){
      this.SelectGroup(this.$route.params.ag);
    }
    this.sel_vessels = this.purchase.vessels.map(e => e.id);
  },
  data:()=>({
    editEmployee:false,
    drag:false,

    employee_id: 0,
    position_id: 0,
    photo_file: 0,
    name:"Eilif",
    surname:"OLSEN",
    status:"-1",
    birth:"1948-01-27",
    birthplace:"Hasvik, Norway",
    nationality:"Norwegian",
    marital:"Married",
    childs21:0,
    street:"Jasna 2",
    city:"Dobra",
    post_code:"72-003",
    country:"Poland",
    phone:"",
    phonem:"+48623442800",
    email:"eilifo234234701@gmail.com",
    nok_name:"Ewa",
    nok_surname:"Kos",
    nok_relationship:"Wife",
    nok_city:"Dobra",
    nok_post_code:"72-003",
    nok_country:"Poland",
    nok_phone:"",
    nok_phonem:"",
    nok_email:"",
    nok_street:"Jasna 2",
    pas_country:"Norway",
    pas_number:"322342349",
    pas_issued:"2018-03-19",
    pas_expirity:"2028-03-19",
    pas_file:0,
    sea_country:"",
    sea_number:"",
    sea_issued:"2020-09-14",
    sea_expirity:"2020-09-14",
    sea_is:0,
    rus_number:"",
    rus_issued:"0000-00-00",
    rus_expirity:"0000-00-00",
    rus_file:0,
    sea_file:0,
    dop_name:"",
    dop_country:"",
    dop_number:"",
    dop_issued:null,
    dop_expirity:null,
    dop_file:0,
    shengen:1,
    shengen_is:0,
    shengen_country:"",
    shengen_number:"",
    shengen_expirity:"0000-00-00",
    shengen_file:0,
    t:"", // telegram
    v:"", // viber
    w:"", // whatsapp

    filters_reserve:false,
    filters_arhive:false,
    sort_list:0,
    // sel_vessels:[],
    sort_lists:[
      [0,'Name Asc'],
      [1,'Name Desc'],
      [2,'Positions Asc'],
      [3,'Positions Desc'],
    ],
  }),
  computed: {
    ...mapState(['purchase']),

    listFilter(){
      // let vessels = this.sel_vessels.length!==0?this.sel_vessels:( this.purchase.vessels.map(e => e.id) );
      // let vessels = this.sel_vessels.length!==0?this.sel_vessels:( [] );
      let vessels = this.purchase.sel_vessels.length!==0?this.purchase.sel_vessels:( [] );
      console.log('vessels',vessels);
      let list = this.purchase.employees.filter(e=>
          e.status>0
          ?(vessels.indexOf(e.status)!==-1):
          (e.status==0
           ?this.filters_reserve
           :this.filters_arhive
          )
      );
      // console.log(list);
      switch(this.sort_list){
        case 0: list=SuperSort(list,[{key:'name',asc:true, as:'string'},{key:'surname',asc:true, as:'string'},]);break;
        case 1: list=SuperSort(list,[{key:'name',asc:false,as:'string'},{key:'surname',asc:false,as:'string'},]);break;
        case 2: list=SuperSort(list,[{key:'position_id',asc:true, as:'string'},{key:'name',asc:true, as:'string'},{key:'surname',asc:true, as:'string'},]);break;
        case 3: list=SuperSort(list,[{key:'position_id',asc:false,as:'string'},{key:'name',asc:false,as:'string'},{key:'surname',asc:false,as:'string'},]);break;
      }
      return list;
    },
    lastLoginPrint(){
      return this.datetimePrint(this.last_login)
    },
    userChanged(){
      if(this.user_id==='new'){return true;}
      let isEdit=false;
      try{
        for(let k of Object.keys(this.user_sel)){
          // if(k==='id'){this.user_id=u[k];}
          if(k==='id'){continue;}
          if(this.user_sel[k]!==this[k]){isEdit=true;break;}
        }
      }catch(e){console.log(e,'this.user_sel',this.user_sel);}
      return isEdit
    },

  },
  methods:{
    SuperSort,
    SaveIt(){
      // this.$store.dispatch('EDIT',{sdf:'asdf',aaa:'sdfds'});
    },
    EmployeeStatus(id){
      if(id<=0){return id===0?'Reserve':'Archive';}
      let v = this.purchase.vessels.find(v=>v.id==id);

      return v!==undefined?v.name:'';
    },
    EmployeePosition(id){
      let u = this.purchase.positions.find(e=>e.id===id)
      return u===undefined?'':(u.name);
    },
    listArr(arr){
      // console.log('listArr(arr)',arr);
      return !arr?[]:(Object.keys(arr).map((key) => {
        return arr[key]
      }).sort());

    },
    datetimePrint(time){
      let dt = (new Date(time * 1000));
      return dt.getFullYear()+'-'+dt.getMonth()+'-'+dt.getDate()+' '+dt.getHours()+':'+dt.getMinutes();
    },
    selectUser(id){
      console.log(id);
      let u;
      if(id!==undefined){
        u=this.purchase.employees.find((u)=>u.id-0===id-0);
        this.user_sel = u;
      }

      if(u!==undefined){
        Object.keys(u).forEach(k=>{
          // if(k==='id'){this.user_id=u[k];}
          if(k==='id'){return;}
          this[k]=u[k];
        });
        this.user_id=u.id;
      }else{
        Object.keys(u).forEach(k=>{
          // if(k==='id'){this.user_id=u[k];}
          if(k==='id'){return;}
          this[k]='';
        });
        this.user_id=u.id;
        this.user_id='new';
      }
      if(id!==undefined && this.$route.path!=='/prs/'+id){
        this.$router.push('/prs/'+id);
      }
    },
    addGroup(){

    },
    SelectGroup(id) {
      let d;
      if(id!==undefined){
        d = this.purchase.access_groups.find((k) => k.id - 0 === id - 0);
        this.ag_sel=d;
      }
      if(d!==undefined){
        this.ag_name=d.name;
        this.roles=d.roles;
        this.ag_id=d.id;
      }else{
        this.ag_name='';
        this.roles=[];
        this.ag_id='new';
      }
      if(id!==undefined && this.$route.path!=='/users/ag/'+id){
        this.$router.push('/users/ag/'+id);
      }
    },
  },
}
</script>


<style lang="stylus">
.AccessGroup_bl{margin-top:24px;}
.AccessGroup_drag{width:10px;height:1.56rem;display:inline-block;vertical-align:middle;opacity:0;}
.AccessGroup_sets{margin:0px 0 2px 6px;vertical-align:middle;display:none;}
.AccessGroup_bl-title{
  .btn-icon{width:20px;height:20px;display:inline-block;vertical-align:middle;cursor:pointer;
    svg{width:20px;height:20px;fill:#007bff;}
  }
  &:hover{
    .AccessGroup_sets{display:inline-block;}
    .AccessGroup_drag{opacity:1;}
  }
}
.AccessGroup_name{display:inline-block;margin-left:6px;vertical-align:middle;font-size:1.3em;}
.user{
  td:nth-child(1){padding-left:18px;}
  .btn-icon{width:20px;height:20px;display:inline-block;vertical-align:middle;cursor:pointer;opacity:0.2;margin: -2px 0 2px 0;
    svg{width:20px;height:20px;fill: #007bff;}
  }
  &:hover{
    td{background:#eee;}
    .btn-icon{opacity:1;}
  }
  &_sel{background:#FFEBA3;}
}

.user_sets{padding:12px;
  label{display:block;padding:6px 0;}
  input:not([type="checkbox"]){display:block;width:100%;max-width:340px;}
  select{display:block;width:100%;max-width:300px;}
  &__left{margin-right:12px;max-width:300px;}
}

.AccessGroup_bl td{padding:3px 12px;}
.ag_sets{
  padding:12px;
  label{display:block;padding:2px 0;}
}
.ag_sel{background:#FFEBA3;}

.EmployeeBl{margin:6px 24px 24px 0px;}
.EmployeeBl--title{background:#005cbf;padding:3px 6px;color:#fff;border-radius:4px;display:inline-block;}

.EmployeeBl__list-item{display:inline-block;width:147px;vertical-align:top;cursor:pointer;margin:6px;
  &:hover{box-shadow:0 0 20px 0 rgba(0,0,0,0.1);}
}
.EmployeeBl__photo{height:187px;width:147px;background:#eee;border:1px solid #ddd;margin:0 auto;position:relative;}
.EmployeeBl__info{margin-top:120px;padding:3px 0 3px 6px;background:rgba(255,255,255,.7);}
.EmployeeBl__info-name{color:#000;white-space: nowrap;overflow: hidden;text-overflow:ellipsis;font-size:0.9em;}
.EmployeeBl__info-pos{color:#ccc;white-space: nowrap;overflow: hidden;text-overflow:ellipsis;font-size:0.8em;}
.EmployeeBl__info-status{color:#fff;position:absolute;top:0;right:0;font-size:0.7em;background:#005cbf;text-transform:uppercase;padding:0 3px;}
.EmployeeBl__info-status0{color:#fff;background:#ccc;} // Резерв
.EmployeeBl__info-status-1{color:#955;background:#fcc;} // Архив
.EmployeeBl__info-status1{color: #ffffff;background: #00889b;} // Zuzanna
.EmployeeBl__info-status7{color: #000;background: #21c6ec;}
.EmployeeBl__info-status6{color: #ffffff;background: #002e72;}
.EmployeeBl__info-status5{color: #d0d0d0;background: #006fff;}
.EmployeeBl__info-status4{color: #001c4c;background: #9cbeec;}
.EmployeeBl__info-status3{color:#fff;background: #5198e2;}

.EmployeeBl__list-item {
  transition: all 0.8s ease;
}
.list-enter-active,
.list-leave-active {
  transition: all 1s ease;
}
.list-leave-active{position: absolute;}
.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: translateY(30px) scale(0);
  //width:0;
}

.EmployeeBl__vessels_bl{white-space: nowrap;
  p{text-align:center;color:#999;font-weight: bold; margin:2em 0 0em 0;}
  label{display:block; padding:6px 24px 6px 12px;
    &:hover{background:rgba(0,0,0,0.04);}
  }
}

.EmployeeBl__vessels{padding:6px 12px;display:inline-block;vertical-align:middle;cursor:default;}

.EmployeeBl__label_menu{padding:6px 12px 6px 6px;
  &:hover{background:rgba(0,0,0,0.04);}
}
.EmployeeBl__sort{
  cursor:default;
  display:inline-block;vertical-align:middle;
  padding:6px 12px 6px 6px;
  &-ico{width:24px;height:24px;display:inline-block;vertical-align:middle;}
  svg{width:24px;height:24px;}
}
.EmployeeBl__vessels,.EmployeeBl__sort{&.DropDown__menu_hover{background:rgba(0,0,0,.04);}}
.EmployeeBl__list-item-sel{&,&:hover{
  box-shadow:0 0 0 4px #ffc107;
  .EmployeeBl__photo{border:1px solid #ffc107;}
}}
</style>
