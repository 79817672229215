<template>
<!--  <div class="Purchase">-->
  <Layouts2side>
    <div class="Purchase__viewDep flex">
      <div class="Purchase__viewDep_i" :class="{Purchase__viewDep_sel: viewDep===1}" @click="viewDep=1">Deck</div>
      <div class="Purchase__viewDep_i" :class="{Purchase__viewDep_sel: viewDep===0}" @click="viewDep=0">Engine</div>
      <div class="Purchase__viewDep_i" :class="{Purchase__viewDep_sel: viewDep===2}" @click="viewDep=2" title="Will be removed in production">All</div>
    </div>
    <table class="Purchase__tbl">
      <tr>
        <th>No.</th>
        <th>Date</th>
        <th title="Priority"></th>
        <th>Dep.</th>
        <th title="Sub-Departament">Sub.</th>
        <th>Category</th>
        <th>Status</th>
      </tr>
      <tr v-for="v in filterPurchase"
          :key="'pl_'+v.id"
          :class="{Purchase__tblrow_sel:v.id == purId}"
          @mouseenter="$event.currentTarget.classList.add('Purchase__tblrow_hover')"
          @mouseleave="$event.currentTarget.classList.remove('Purchase__tblrow_hover')"
          @click="select(v.id)"
      >
        <td class="Purchase__no">{{ v.no }}</td>
        <td class="Purchase__date">{{ v.date.substring(0,10) }}</td>
        <td class="Purchase__priority">
          <span class="SelectDot__dot" :title="'Priority: '+purchase.priority[(v.priority||0)-0].name"
                :style="{
                            backgroundColor: purchase.priority[(v.priority||0)-0].color
                          }"
          ></span>
        </td>
        <td class="Purchase__dep"><div>{{ dep(v.purchase_deps_id).name }}</div></td>
        <td>{{ subDep(v.purchase_deps_id).name }}</td>
        <td>{{purchase.deps[v.purchase_deps_id].name}}</td>
        <td :style="{color:purchase.status[(v.status||0)-0].color}">{{ purchase.status[(v.status||0)-0].name }}</td>
      </tr>
    </table>
    <template #wind>
      <div class="Purchase__item">
        <div class="Purchase__item-panel" v-if="!!purId">
          <div class="Purchase__item-title flex">
            <Btn color="yellow" size="sm">Save</Btn>
            <div class="flex-sp"></div>
            <span class="Purchase__item-date" :title="purchase.list[purId].date||''">{{ (purchase.list[purId].date||'').substring(0,10) }}</span>

            <SelectDot v-model="sel_priority" :options="purchase.priority" :width="150">
              <template #header>Priority</template>
            </SelectDot>
            <span class="Purchase__item-no">No. {{purchase.list[purId].no||''}}</span>
            <div class="flex-sp"></div>
            <span class="Purchase__files" :class="{Purchase__files_no:(purchase.list[purId].attachments||[]).length==0}">
              <span class="Purchase__files-count" v-if="purchase.list[purId].attachments.length>0">{{purchase.list[purId].attachments.length}}</span>
              <span class="Purchase__files-ico"><svg><use xlink:href="#icon-attach"></use></svg></span>
            </span>
            <div class="flex-sp"></div>
            <button class="btn-pdf" style="vertical-align: middle"><svg><use xlink:href="#icon-pdf"></use></svg></button>
            <div class="flex-sp"></div>
          </div>
          <div class="Purchase__item_l2 flex flex-bottom-border flex-wrap">
            <div class="flex-sp">
              <div class="flex flex-wrap flex-left Purchase__item-param">
                <label class="Purchase__item_maker" for="Purchase__item_maker">
                  <div class="Purchase__item_maker_hidden">Model:</div>
                  <div class="Purchase__item_maker_visible">Maker:</div>
                </label>
                <div class="flex-sp Purchase__input"><input id="Purchase__item_maker" class="input&#45;&#45;block input__line" v-model="sel_marker"></div>
              </div>
              <div class="flex flex-wrap flex-left Purchase__item-param">
                <label class="Purchase__item_serial" for="Purchase__item_serial" title="Model/Serial No:">Model:</label>
                <div class="flex-sp Purchase__input"><input id="Purchase__item_serial" class="input&#45;&#45;block input__line" v-model="sel_model_serial"></div>
              </div>
            </div>
            <div style="text-align: center;">
              <label>
                {{dep(sel_purchase_deps_id).name}} /
                {{subDep(sel_purchase_deps_id).name}}
                <div class="Purchase__input">
                  <select v-model="sel_purchase_deps_id">
                    <template v-for="v in Object.values(purchase.deps).filter((el) =>el.parent_id == 0)" :key="'dep'+v.id">
                      <option :value="v.id"  class="Purchase__sel-dep" disabled>{{v.name}}</option>
                      <template v-for="v1 in Object.values(purchase.deps).filter((el) =>el.parent_id == v.id)" :key="'sub'+v1.id">
                        <option :value="v1.id" class="Purchase__sel-sub" disabled>{{v1.name}}</option>
                        <option v-for="v2 in Object.values(purchase.deps).filter((el) =>el.parent_id == v1.id)"
                                :key="'pr'+v2.id"
                                :value="v2.id"
                                class="Purchase__sel-cat"
                                :selected="v2.id===sel_purchase_deps_id"
                        >{{v2.name}}</option>
                      </template>
                    </template>
                  </select>
                </div>
              </label>
            </div>
          </div>
        </div>
        <div class="Purchase__tbl-bl" v-if="!!purId">
          <div class="Purchase__tbl-bl_">
            <table class="Purchase__tbl">
              <thead>
              <!--              <tr>
                              <th rowspan="2">No</th>
                              <th rowspan="2">Description</th>
                              <th rowspan="2">Cat./ref. No.</th>
                              <th rowspan="2">Unit</th>
                              <th colspan="2">Quantity</th>
                            </tr>
                            <tr>
                              <th title="Quantity Ordered">Ord.</th>
                              <th title="Quantity Delivered">Del.</th>
                            </tr>-->
              <tr>
                <th>No</th>
                <th>Description</th>
                <th>Cat./ref. No.</th>
                <th>Unit</th>
                <th title="Quantity Ordered">Ord.</th>
                <th title="Quantity Delivered">Del.</th>
              </tr>
              </thead>
              <draggable
                  v-model="sel_list"
                  tag="tbody"
                  draggable=".Purchase__list_row"
                  handle=".Purchase__list_drag"
                  ghost-class="Purchase__list_drag_ghost"
                  v-bind="{
                            animation:600,
                            group: 'description',
                            // onChange:function(){$emit('update');},
                            // onEnd:function(){$emit('update');},
                          }"
                  @start="drag = true;"
                  @end="drag = false;"
                  :disabled="false"
              >
                <transition-group type="transition" :name="!drag ? 'flip-list' : null">

                  <tr v-for="(v,i) in sel_list"
                      :key="'pl_'+v.id"
                      class="Purchase__list_row"
                      @mouseenter="$event.currentTarget.classList.add('Purchase__tblrow_hover')"
                      @mouseleave="$event.currentTarget.classList.remove('Purchase__tblrow_hover')"
                  >
                    <td class="Purchase__list_no">
                      <span class="Purchase__list_no_out">{{ i+1 }}</span>
                      <span class="Purchase__list_no_hover">
                        <span class="Purchase__list_drag">
                          <svg><use xlink:href="#icon-drag"></use></svg>
                        </span>
                        <button class="btn-remove" title="Remove" @click="sel_list.splice(sel_list.findIndex((el)=>el.id == v.id), 1)">
                          <svg><use xlink:href="#icon-del"></use></svg>
                        </button>
                      </span>

                    </td>
                    <td class="Purchase__list_desc" contenteditable="true">{{ v.description }}</td>
                    <td class="Purchase__list_cat" contenteditable="true">{{ v.cat_ref_no }}</td>
                    <td class="Purchase__list_unit" contenteditable="true">{{ v.unit}}</td>
                    <td class="Purchase__list_ord" contenteditable="true">{{ v.quantity_ordered }}</td>
                    <td class="Purchase__list_del" contenteditable="true">{{ v.quantity_delivered }}</td>
                  </tr>
                </transition-group>
              </draggable>
            </table>


            <button class="btn-add" @click="sel_list.push({
                     id: next,
                     description: '',
                     cat_ref_no: '',
                     unit: '',
                     quantity_ordered: 0,
                     quantity_delivered: 0,
                     note: ''
                 })">
              <span class="btn-icon"><svg><use xlink:href="#icon-plus"></use></svg></span>
              <span class="btn-text">Add</span>
            </button>
          </div>
        </div>
        <div class="Purchase__item-footer" v-if="!!purId">
        <div class="item-footer__title">Remarks</div>
        <div class="item-footer__text">
          <textarea v-model="sel_remarks" class="item-footer__textarea" placeholder="Remarks..."></textarea>
        </div>
        <div class="item-footer__l2">
          <label v-if="sel_date_delivery">Date of Delivery: <span>{{sel_date_delivery}}</span></label>
          <label v-if="sel_confirmed_by">Confirmed by: <span>{{sel_confirmed_by}}</span></label>
          <label v-if="sel_closure_date">Closure Date: <span>{{sel_closure_date}}</span></label>
          <label v-if="sel_performed_by">Closed by: <span>{{sel_performed_by}}</span></label>
        </div>
      </div>
      </div>
    </template>
  </Layouts2side>
<!--
    <div class="Purchase__vp"
         :class="{
                      Purchase__vp_horiz:horiz==0,
                      Purchase__vp_wind: horiz==2,
                      Purchase__vp_fullWindow: fullWindow,
                      Purchase__vp_leftmenu:sidebarView
                 }"
    >
      <div class="Purchase__vp1" :class="{Purchase__vp1_only:!purId || purchase.list[purId]===undefined}">
        <div class="Purchase__viewDep flex">
          <div class="Purchase__viewDep_i" :class="{Purchase__viewDep_sel: viewDep===1}" @click="viewDep=1">Deck</div>
          <div class="Purchase__viewDep_i" :class="{Purchase__viewDep_sel: viewDep===0}" @click="viewDep=0">Engine</div>
          <div class="Purchase__viewDep_i" :class="{Purchase__viewDep_sel: viewDep===2}" @click="viewDep=2" title="Will be removed in production">All</div>
          <div class="Purchase__layouts" title="Window Views">
            <label class="Purchase__icons-layout">
              <input v-model="horiz" type="radio" value="0">
              <i><svg><use xlink:href="#icon-view-vert"></use></svg></i>
            </label>
            <label class="Purchase__icons-layout">
              <input v-model="horiz" type="radio" value="1">
              <i><svg><use xlink:href="#icon-view-horiz"></use></svg></i>
            </label>
            <label class="Purchase__icons-layout">
              <input v-model="horiz" type="radio" value="2">
              <i><svg><use xlink:href="#icon-view-wind"></use></svg></i>
            </label>
          </div>
        </div>
        <table class="Purchase__tbl">
          <tr>
            <th>No.</th>
            <th>Date</th>
            <th title="Priority"></th>
            <th>Dep.</th>
            <th title="Sub-Departament">Sub.</th>
            <th>Category</th>
            <th>Status</th>
          </tr>
          <tr v-for="v in filterPurchase"
              :key="'pl_'+v.id"
              :class="{Purchase__tblrow_sel:v.id == purId}"
              @mouseenter="$event.currentTarget.classList.add('Purchase__tblrow_hover')"
              @mouseleave="$event.currentTarget.classList.remove('Purchase__tblrow_hover')"
              @click="select(v.id)"
          >
            <td class="Purchase__no">{{ v.no }}</td>
            <td class="Purchase__date">{{ v.date.substring(0,10) }}</td>
            <td class="Purchase__priority">
              <span class="SelectDot__dot" :title="'Priority: '+purchase.priority[(v.priority||0)-0].name"
                    :style="{
                              backgroundColor: purchase.priority[(v.priority||0)-0].color
                            }"
              ></span>
            </td>
            <td class="Purchase__dep"><div>{{ dep(v.purchase_deps_id).name }}</div></td>
            <td>{{ subDep(v.purchase_deps_id).name }}</td>
            <td>{{purchase.deps[v.purchase_deps_id].name}}</td>
            <td :style="{color:purchase.status[(v.status||0)-0].color}">{{ purchase.status[(v.status||0)-0].name }}</td>
          </tr>
        </table>
      </div>
      <div class="Purchase__vp2 Purchase__item" v-if="(!!purId) && purchase.list[purId]!==undefined">
        <div class="Purchase__item-panel">
          <div class="Purchase__item-title flex">
            <Btn color="yellow" size="sm">Save</Btn>
            <div class="flex-sp"></div>
            <span class="Purchase__item-date" :title="purchase.list[purId].date">{{purchase.list[purId].date.substring(0,10)}}</span>

            <SelectDot v-model="sel_priority" :options="purchase.priority" :width="150">
              <template #header>Priority</template>
            </SelectDot>
            <span class="Purchase__item-no">No. {{purchase.list[purId].no}}</span>
            <div class="flex-sp"></div>
            <span class="Purchase__files" :class="{Purchase__files_no:(purchase.list[purId].attachments||[]).length==0}">
              <span class="Purchase__files-count" v-if="purchase.list[purId].attachments.length>0">{{purchase.list[purId].attachments.length}}</span>
              <span class="Purchase__files-ico"><svg><use xlink:href="#icon-attach"></use></svg></span>
            </span>
            <div class="flex-sp"></div>
            <button class="btn-pdf" style="vertical-align: middle"><svg><use xlink:href="#icon-pdf"></use></svg></button>
            <div class="flex-sp"></div>
            <div class="Purchase__btnswind">
              <button class="btn-full" @click="fullWindow=!fullWindow"><span>&#9633;</span></button>
              <button class="btn-close" @click="$router.push('/pur')"><span>&#128937;</span></button>
            </div>
          </div>
          <div class="Purchase__item_l2 flex flex-bottom-border flex-wrap">
            <div class="flex-sp">
              <div class="flex flex-wrap flex-left Purchase__item-param">
                <label class="Purchase__item_maker" for="Purchase__item_maker">
                  <div class="Purchase__item_maker_hidden">Model:</div>
                  <div class="Purchase__item_maker_visible">Maker:</div>
                </label>
                <div class="flex-sp Purchase__input"><input id="Purchase__item_maker" class="input&#45;&#45;block input__line" v-model="sel_marker"></div>
              </div>
              <div class="flex flex-wrap flex-left Purchase__item-param">
                <label class="Purchase__item_serial" for="Purchase__item_serial" title="Model/Serial No:">Model:</label>
                <div class="flex-sp Purchase__input"><input id="Purchase__item_serial" class="input&#45;&#45;block input__line" v-model="sel_model_serial"></div>
              </div>
            </div>
            <div style="text-align: center;">
              <label>
                {{dep(sel_purchase_deps_id).name}} /
                {{subDep(sel_purchase_deps_id).name}}
                <div class="Purchase__input">
                  <select v-model="sel_purchase_deps_id">
                    <template v-for="v in Object.values(purchase.deps).filter((el) =>el.parent_id == 0)" :key="'dep'+v.id">
                      <option :value="v.id"  class="Purchase__sel-dep" disabled>{{v.name}}</option>
                      <template v-for="v1 in Object.values(purchase.deps).filter((el) =>el.parent_id == v.id)" :key="'sub'+v1.id">
                        <option :value="v1.id" class="Purchase__sel-sub" disabled>{{v1.name}}</option>
                        <option v-for="v2 in Object.values(purchase.deps).filter((el) =>el.parent_id == v1.id)"
                                :key="'pr'+v2.id"
                                :value="v2.id"
                                class="Purchase__sel-cat"
                                :selected="v2.id===sel_purchase_deps_id"
                        >{{v2.name}}</option>
                      </template>
                    </template>
                  </select>
                </div>
              </label>
            </div>
          </div>
        </div>
        <div class="Purchase__tbl-bl">
          <div class="Purchase__tbl-bl_">
            <table class="Purchase__tbl">
              <thead>
&lt;!&ndash;              <tr>
                <th rowspan="2">No</th>
                <th rowspan="2">Description</th>
                <th rowspan="2">Cat./ref. No.</th>
                <th rowspan="2">Unit</th>
                <th colspan="2">Quantity</th>
              </tr>
              <tr>
                <th title="Quantity Ordered">Ord.</th>
                <th title="Quantity Delivered">Del.</th>
              </tr>&ndash;&gt;
              <tr>
                <th>No</th>
                <th>Description</th>
                <th>Cat./ref. No.</th>
                <th>Unit</th>
                <th title="Quantity Ordered">Ord.</th>
                <th title="Quantity Delivered">Del.</th>
              </tr>
              </thead>
              <draggable
                  v-model="sel_list"
                  tag="tbody"
                  draggable=".Purchase__list_row"
                  handle=".Purchase__list_drag"
                  ghost-class="Purchase__list_drag_ghost"
                  v-bind="{
                            animation:600,
                            group: 'description',
                            // onChange:function(){$emit('update');},
                            // onEnd:function(){$emit('update');},
                          }"
                  @start="drag = true;"
                  @end="drag = false;"
                  :disabled="false"
              >
                <transition-group type="transition" :name="!drag ? 'flip-list' : null">

                  <tr v-for="(v,i) in sel_list"
                      :key="'pl_'+v.id"
                      class="Purchase__list_row"
                      @mouseenter="$event.currentTarget.classList.add('Purchase__tblrow_hover')"
                      @mouseleave="$event.currentTarget.classList.remove('Purchase__tblrow_hover')"
                  >
                    <td class="Purchase__list_no">
                      <span class="Purchase__list_no_out">{{ i+1 }}</span>
                      <span class="Purchase__list_no_hover">
                        <span class="Purchase__list_drag">
                          <svg><use xlink:href="#icon-drag"></use></svg>
                        </span>
                        <button class="btn-remove" title="Remove" @click="sel_list.splice(sel_list.findIndex((el)=>el.id == v.id), 1)">
                          <svg><use xlink:href="#icon-del"></use></svg>
                        </button>
                      </span>

                    </td>
                    <td class="Purchase__list_desc" contenteditable="true">{{ v.description }}</td>
                    <td class="Purchase__list_cat" contenteditable="true">{{ v.cat_ref_no }}</td>
                    <td class="Purchase__list_unit" contenteditable="true">{{ v.unit}}</td>
                    <td class="Purchase__list_ord" contenteditable="true">{{ v.quantity_ordered }}</td>
                    <td class="Purchase__list_del" contenteditable="true">{{ v.quantity_delivered }}</td>
                  </tr>
                </transition-group>
              </draggable>
            </table>


            <button class="btn-add" @click="sel_list.push({
                     id: next,
                     description: '',
                     cat_ref_no: '',
                     unit: '',
                     quantity_ordered: 0,
                     quantity_delivered: 0,
                     note: ''
                 })">
              <span class="btn-icon"><svg><use xlink:href="#icon-plus"></use></svg></span>
              <span class="btn-text">Add</span>
            </button>
          </div>
        </div>
        <div class="Purchase__item-footer">
          <div class="item-footer__title">Remarks</div>
          <div class="item-footer__text">
            <textarea v-model="sel_remarks" class="item-footer__textarea" placeholder="Remarks..."></textarea>
          </div>
          <div class="item-footer__l2">
            <label v-if="sel_date_delivery">Date of Delivery: <span>{{sel_date_delivery}}</span></label>
            <label v-if="sel_confirmed_by">Confirmed by: <span>{{sel_confirmed_by}}</span></label>
            <label v-if="sel_closure_date">Closure Date: <span>{{sel_closure_date}}</span></label>
            <label v-if="sel_performed_by">Closed by: <span>{{sel_performed_by}}</span></label>
          </div>
        </div>
      </div>
    </div>-->
<!--  </div>-->
</template>

<script>
import { mapState/*, mapActions*/ } from 'vuex';
import { log,logRez } from 'mylib/Dev';
import Btn from "../components/ui/Btn";
// import Select from 'inputs/Select.vue';
import SelectDot from "../components/ui/SelectDot";
import {VueDraggableNext} from 'vue-draggable-next'
import Layouts2side from "@/views/Layouts/Layouts2side"; // vue 3

export default {
  name: "Purchase",
  components: {
    Layouts2side,
    SelectDot,
    Btn,
    draggable:VueDraggableNext,
    // Select
  },
  mounted(){
    if(this.$route.params.id!==undefined){
      this.select(this.$route.params.id);
    }
  },
  computed:{
    ...mapState(['purchase']),
    purId(){return (this.$route.params.id - 0 )||false ;},

    sidebarView(){return this.$root.sidebarView;},
    filterPurchase(){
      return this.purchase.list.filter((it)=>{
        return this.viewDep==2
            ? true
            : ( this.viewDep==1
                    ? this.purchase.deps[this.purchase.deps[it.purchase_deps_id].parent_id].parent_id == 1
                    : this.purchase.deps[this.purchase.deps[it.purchase_deps_id].parent_id].parent_id == 2
            )
      })
    }
  },
  data:()=>({
    drag:false,
    viewDep:0, // 0 - Eng, 1 - Deck, 2 - All

    fullWindow:false,
    horiz:0,

    sel_purchase_deps_id:44,
    sel_vessel:0,
    sel_priority:0,
    sel_status:0,
    sel_marker:0,
    sel_remarks:0,
    sel_date_delivery:'',
    sel_confirmed_by:0,
    sel_closure_date:'',
    sel_performed_by:0,
    sel_model_serial:'',
    sel_list:[],

  }),
  methods:{
    // ...mapActions(['addProduct','delProduct']),
    try_(func,catch_){
      try{
        return func();
      }catch(e){
        return catch_;
      }
    },
    dep(id){
      // console.log('dep',id);
      // return this.try_(function(){return this.purchase.deps[this.purchase.deps[this.purchase.deps[id].parent_id].parent_id];},'');
      return this.purchase.deps[this.purchase.deps[this.purchase.deps[id].parent_id].parent_id];
    },
    subDep(id){
      // return this.try_(function(){ return this.purchase.deps[this.purchase.deps[id].parent_id];},'');
      return this.purchase.deps[this.purchase.deps[id].parent_id];
    },

    select(id){
      let i = this.purchase.list.find(e=>e.id==id);
      if(i==null || i==undefined){return;}
      console.log('select',id,this.purId,i);
      this.sel_purchase_deps_id = i.purchase_deps_id;
      this.sel_vessel = i.vessel;
      this.sel_priority = i.priority;
      this.sel_status = i.status;
      this.sel_marker = i.marker;
      this.sel_remarks = i.remarks;
      this.sel_date_delivery = i.date_delivery;
      this.sel_confirmed_by = i.confirmed_by;
      this.sel_closure_date = i.closure_date;
      this.sel_performed_by = i.performed_by;
      this.sel_model_serial = i.model_serial;
      this.sel_list = JSON.parse(JSON.stringify(i.list));

      this.$router.push('/pur/'+id);
    },
    log,logRez,
  }
}
</script>

<style lang="stylus">
.Purchase{}


.Purchase__vp.Purchase__vp_leftmenu{left:220px;}


.mainleftmenu .Purchase__vp.Purchase__vp_leftmenu{ left:660px;}
.appSize0.mainleftmenu .Purchase__vp.Purchase__vp_leftmenu{ left:560px;}
.appSize2.mainleftmenu .Purchase__vp.Purchase__vp_leftmenu{ left:800px;}

.Purchase__tbl-bl{flex:1 0 auto;position:relative}
.Purchase__tbl-bl_{overflow: auto;position:absolute;top:0;left:0;bottom:0;right:0;}
.Purchase__item{display:flex;flex-direction: column}
.Purchase__item-footer{background:#eeeeee;padding:6px;}
.item-footer__textarea{width:100%;height:60px;
  &:focus,&:hover{height:200px}
}
.item-footer__l2{
  label{display:inline-block;margin-right:12px;}
  label:last-child{margin-right:0}
}

.Purchase__tbl{
  width:100%;
  border-collapse: collapse;
  td,th{border:1px solid #eee;padding:3px 6px;}
  th{background:#f4f4f4;text-align:center;}
}
.Purchase__tblrow_hover td{background:#eee;}
.Purchase__tblrow_sel td{background: #ffeba3;}
td.Purchase__dep{text-align:left;}
//td.Purchase__dep{padding:0;width:50px}
//td.Purchase__dep div{width:50px;text-align:left;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;padding:3px 6px;}
td.Purchase__no{text-align:right}
td.Purchase__date{white-space:nowrap;}
td.Purchase__list_desc{position:relative;padding:0;width:30%;
  &,& div{white-space:normal;overflow:hidden;padding:3px 6px;
  }
}
td.Purchase__list_cat{position:relative;padding:0;width:30%;
  &,& div{white-space:normal;overflow:hidden;padding:3px 6px;}
}

td.Purchase__list_no{width:25px;padding:3px 0;text-align:center;background:#f4f4f4}
td.Purchase__list_unit{width:45px;padding:3px 2px;text-align:center;}
td.Purchase__list_ord{width:45px;padding:3px 2px;text-align:center;}
td.Purchase__list_del{width:45px;padding:3px 2px;text-align:center;}
td.Purchase__priority{width:24px;padding:3px 2px;text-align:center;}

.Purchase__item-panel{background:#eee;padding:3px 6px;position:relative;}

.Purchase__item-title{}
.Purchase__item-no{font-size:1.2em;color:#000;margin:0 12px 0 6px;font-weight:bold;}
.Purchase__item-date{color: #007bff;padding:0 6px 0 12px;}

.Purchase__sel-dep{background: #868686;color:#fff;line-height:2em;text-align:center;font-size:1.5em;}
.Purchase__sel-sub{background:#ddd;color:#000;font-size:1.25em;}
.Purchase__sel-cat{}

.Purchase__item_maker,.Purchase__item_serial{position:relative;text-align:right;padding-right:6px;}
.Purchase__item-param{color:#999;
  &:hover{color:#333;}
}
.Purchase__item_maker_hidden{opacity:0}
.Purchase__item_maker_visible{position:absolute;top:0;left:0;right:0;bottom:0;padding-right:6px;}
.Purchase__viewDep{padding-right:96px;}
.Purchase__viewDep_i{flex:1 0 auto;text-align:center; padding:3px 6px;font-weight:bold;}
.Purchase__viewDep_sel{background:#FFEBA3;}
.Purchase__input{margin:2px;}
.btn-pdf{border:0;outline:0;
  background:transparent;
  padding:0;
  cursor:pointer;
  svg{fill:#B81C08;width:32px;height:32px}
  &:hover{
    background:transparent;
    svg{fill: #e72e1b}
  }
}
.btn-add{border:0;outline:0;
  background:#169400;
  padding:6px 12px 6px 6px;
  cursor:pointer;
  vertical-align:middle;
  svg{fill: #fff;width:24px;height:24px}
  &:hover{
    background:#29b70d;
    svg{fill: #fff;}
  }
  margin:5px;
  border-radius: .25rem;
}
.btn-icon{vertical-align:middle;display:inline-block;margin:1px 5px -1px 0;}
.btn-text{color:#fff;font-size:16px;vertical-align:middle;display:inline-block;}

.btn-remove{border:0;outline:0;vertical-align:middle;
  background:transparent;
  padding:0;
  margin:0;
  cursor:pointer;
  svg{display:block;fill:#B81C08;width:24px;height:24px}
  &:hover{
    background:transparent;
    svg{fill: #e72e1b}
  }
}
.Purchase__list_drag{display:inline-block;vertical-align:middle;
  background:transparent;
  padding:0;
  margin:0;
  cursor:move;
  svg{display:block;fill:#ccc;width:24px;height:24px}
  &:hover{
    background:transparent;
    svg{fill: #999}
  }
}
.Purchase__list_no{min-width:48px;min-height:38px;}
.Purchase__list_no_out{line-height:24px;display:inline-block;vertical-align:middle;}
.Purchase__tblrow_hover .Purchase__list_no_out,.Purchase__list_no_hover{display:none}
.Purchase__tblrow_hover .Purchase__list_no_hover{display:inline-block;}
.Purchase__tblrow_hover td.Purchase__list_no{padding-left:0;padding-right:0;}

.Purchase__files{
  background:#005cbf;
  display:inline-block;
  vertical-align: middle
  border-radius:30px;
  padding:3px 6px 3px 12px;
  color:#fff;
  margin-right:24px;
  cursor:pointer;
  &-count{font-weight:bold;display:inline-block;vertical-align: middle}
  &-ico{width:20px;height:20px;display:inline-block;vertical-align: middle}
  svg{width:20px;height:20px;fill:#fff;}
  &_no{padding:3px 6px 3px 6px;background:rgba(0,0,0,.1);svg{fill:#005cbf;}}
}

.input__line{
  background:none;
  border:0;
  border-radius:0;
  padding:0;
  line-height:1.25em;
  border-bottom:2px solid rgba(0,0,0,.1);
  height:auto;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  &:focus{outline:0;box-shadow:none;color:#000;}
}
</style>
