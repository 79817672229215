import Axios from 'axios';

function randomString(len) {
    var chrs = '             abdehkmnpswxzABDEFGHKMNPQRSTWXZ123456789';
    var str = '';
    for (var i = 0; i < len; i++) {
        var pos = Math.floor(Math.random() * chrs.length);
        str += chrs.substring(pos,pos+1);
    }
    return str;
}



export default {
    actions: {
        RELOAD: async (context) => { // RELOAD: async (context, payload) => {
            console.log('reload',this);
            let data = await Axios.get(context.state.domen+'/api/v1/all.php?__='+Math.random());
            console.log('RELOAD rez',data);
            context.commit('SET_ALL_DATA', data.data);
        },
        EDIT: async (context, post_data) => {
            console.log('EDIT post_data',post_data);
            let data = await Axios.post(context.state.domen+'/api/v1/all.php?__='+Math.random(),post_data);
            console.log('EDIT rez',data);
            context.commit('SET_ALL_DATA', data.data);
        },

        DELETE: async (context, post_data) => {
            console.log('EDIT post_data',post_data);
            let data = await Axios.post(context.state.domen+'/api/v1/all.php?__='+Math.random(),post_data);
            console.log('EDIT rez',data);
            context.commit('SET_ALL_DATA', data.data);
        },
        // addProduct(context,list_id){context.commit('addProduct',list_id);},
        // delProduct(context,list_id,product_line){context.commit('delProduct',list_id,product_line);}
    },
    mutations:{
        SET_ALL_DATA(state, payload){
            if(process.env.NODE_ENV !== 'production'){console.log('Обновляем данные целиком');}

            function EmployeePosition(id){
                let u = state.positions.find(e=>e.id===id)
                return u===undefined?'':(u.name);
            }
            function EmployeeStatus(id){
                if(id<=0){return id===0?'Reserve':'Archive';}
                let v = state.vessels.find(v=>v.id==id);

                return v!==undefined?v.name:'';
            }

            if(payload.purchase!==undefined){state.purchase = payload.purchase;}
            if(payload.deps!==undefined){state.deps = payload.deps;}
            if(payload.access_groups!==undefined){state.access_groups = payload.access_groups;}
            if(payload.roles!==undefined){state.roles = payload.roles;}
            if(payload.users!==undefined){state.users = payload.users;}
            if(payload.vessels!==undefined){
                state.vessels = payload.vessels;
                state.sel_vessels = state.sel_vessels.length===0?(payload.vessels.map(e => e.id)):(state.sel_vessels);
            }
            if(payload.positions!==undefined){state.positions = payload.positions;}
            if(payload.machinery!==undefined){state.machinery = payload.machinery;}
            if(payload.employees!==undefined){
                state.employees = payload.employees.map(u=>{
                    let k = u;
                    k.EmployeePosition = EmployeePosition(u.position_id);
                    k.EmployeeStatus   = EmployeeStatus(u.status);
                    return k;
                });
            }
            if(payload.result!==undefined){state.lastsave.result = payload.result;}
            if(payload.log   !==undefined){state.lastsave.log    = payload.log;}
            if(payload.error !==undefined){state.lastsave.error  = payload.error;}
        },
        // addProduct(state,list_id){
        //     const next = state.list[list_id].list.reduce(function (max,cur){return max<cur.id?cur.id:max},0)+1;
        //     state.list[list_id].list.push({
        //         id: next,
        //         description: '',
        //         cat_ref_no: '',
        //         unit: '',
        //         quantity_ordered: 0,
        //         quantity_delivered: 0,
        //         note: ''
        //     });
        // },
        // delProduct(state,list_id,product_line){
        //     state.list[list_id].list = state.list[list_id].list.filter((_,index)=>product_line==index);
        // }
    },
    state:{
        lastsave:{
            result:null,
            log:null,
            error:null,
        },
        domen:process.env.NODE_ENV === 'production'?'http://sms.artgroup.ru':'http://sms.ru',
        menu:{
            manuals:{       to: '/mnl', icon:'icon-book',   name:'Manuals',                                    short:'Manuals',                                        news:[],edited:[]},
            certification:{ to: '/crt', icon:'icon-sert',   name:'Certification',                              short:'Certification',                                  news:[],edited:[]},
            personnel:{     to: '/prs', icon:'icon-users',  name:'Personnel management',                       short:'Personnel management',                           news:[],edited:[]},
            purchase:{      to: '/pur', icon:'icon-pays',   name:'Purchase',                                   short:'Purchase',                                       news:['i'],edited:['i']},
            maintenance:{   to: '/pln', icon:'icon-tools',  name:'Planned maintenance',                        short:'Planned maintenance',                            news:[],edited:[]},
            emergency:{     to: '/emr', icon:'icon-bell',   name:'Emergency preparedness Training and drills', short:'Emergency preparedness<br/>Training and drills', news:[],edited:[]},
            operations:{    to: '/shp', icon:'icon-tiling', name:'Ship operations',                            short:'Ship operations',                                news:[],edited:[]},
            verifications:{ to: '/vrf', icon:'icon-checks', name:'System verifications',                       short:'System verifications',                           news:[],edited:[]},
            reporting:{     to: '/rep', icon:'icon-report', name:'Reporting System Follow Up Control',         short:'Reporting System<br/>Follow Up Control',         news:[],edited:[]},
        },
        priority:{
            0:{id:0,name:'Normal',   color:'#000'},    // Обычный. Обычный уровень. Доставка при первой возможности,
                                                       // когда запрос рассмотрен, утвержден, профинансирован,
                                                       // материалы собраны и готовы к доставке в соответствии с
                                                       // обычными операционными планами Компании
            1:{id:1,name:'Urgent',   color:'#06f'},    // Срочный. Высокий приоритет, доставка в кратчайшие, насколько
                                                       // это практически возможно, сроки, однако компания должна учитывать
                                                       // действительную необходимость исходя из текущих планов и графиков
                                                       // движения судов
            2:{id:2,name:'Emergency',color:'#c00'},    // Аварийный. Аварийная доставка – наивысший приоритет,
                                                       // требуются немедленные действия, максимально быстрая доставка,
                                                       // независимо от времени суток, задействованной рабочей силы и
                                                       // места дислокации судна
        },
        status:{
            0:{id:0,name:'Closed',      color:'#c00'}, // «Естественное» (полная доставка) или принудительное закрытие заявки
            1:{id:1,name:'Open',        color:'#090'}, // При:
                                                       //     а) создании и сохранении новой заявки
                                                       //     b) сохранении отредактированной заявки
            2:{id:2,name:'Approval',    color:'#06f'}, // При открытии новой заявки пользователем Management
            3:{id:3,name:'On delivery', color:'#06f'}, // При полном одобрении заявки
            4:{id:4,name:'Partial',     color:'#06f'}, // При частичной доставке
            5:{id:5,name:'Denied',      color:'#000'}, // При отказе в финансировании заявки
            6:{id:6,name:'Void',        color:'#000'}, // Аннулирование заявки
            7:{id:7,name:'Edit',        color:'#090'}, // Требование редактирования заявки
        },
        list:[

            ...'s'.repeat(500).split('').map(function(v,i){
                return {
                    id: i+1,
                    purchase_deps_id: 13+Math.round(Math.random()*31),
                    vessel: Math.round(Math.random()*1),
                    date: "2020-11-12 19:13:25",
                    priority: Math.floor(Math.random() * 3),
                    status: Math.floor(Math.random() * 8),
                    marker: randomString(60),
                    no: i+3,
                    remarks: randomString(200),
                    date_delivery: [null,"2020-10-0"+Math.floor(Math.random() * 9)][Math.floor(Math.random() * 1)],
                    confirmed_by: [null,'Bob','Mark','Stive','Alexander','Alexey','Helga'][Math.floor(Math.random() * 7)],
                    closure_date: [null,"2020-10-1"+Math.floor(Math.random() * 9)][Math.floor(Math.random() * 1)],
                    performed_by: [null,'Bob','Mark','Stive','Alexander','Alexey','Helga'][Math.floor(Math.random() * 7)],
                    model_serial: randomString(12),
                    attachments: 's'.repeat(Math.round(Math.random()*5)).split('').
                            map(function() {
                                return  randomString(10)+'.'+['jpg','pdf','doc'][Math.floor(Math.random()*2.9)];
                            }),
                    list: 's'.repeat(Math.round(Math.random()*30)+1).split('').
                        map(function(v,i) {
                            return {
                                id: i+1,
                                description: randomString(60),
                                cat_ref_no: randomString(10),
                                unit: 'pounds',
                                quantity_ordered: Math.round(Math.random()*100000),
                                quantity_delivered:  Math.round(Math.random()*100000),
                                note: randomString(100)
                            };
                    }),
                };
            })
        ],
        deps:{},
        access_groups:[],
        roles:[],
        users:[],
        employees:[],
        vessels:[],
        sel_vessels:[],
        positions:[],
        machinery:[]
    },
    getters:{
        // getDeps(state){ return state.deps; },
    },
}
