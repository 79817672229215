<template>
  <div class="ui-table">
    <div class="ui-table__header">
      <div class="ui-table__header-col-place"
           v-for="(col,index) in columns"
           :key="col.id||index"
           :style="styl(col)"
      >
        <ui-table-header-col :col="col"/>
      </div>
    </div>
    <div class="ui-table__body">
      <div class="ui-table__row" v-for="(row,rindex) in rows" :key="row.id||rindex">
        <template
             v-for="(col,index) in columns"
             :key="col.id||index"
        >
          <div class="ui-table__row-col" :style="styl(col)" v-if="col.children===undefined">{{row[col.id]}}</div>
          <div class="ui-table__row-col" v-else
               v-for="(i,index) in col.children"
               :style="styl(i)"
               :key="i.id||index">{{row[col.id]}}</div>
        </template>
      </div>
    </div>
<!--    <draggable
        v-model="sel_list"
        tag="tbody"
        draggable=".Purchase__list_row"
        handle=".Purchase__list_drag"
        ghost-class="Purchase__list_drag_ghost"
        v-bind="{
                            animation:600,
                            group: 'description',
                            // onChange:function(){$emit('update');},
                            // onEnd:function(){$emit('update');},
                          }"
        @start="drag = true;"
        @end="drag = false;"
        :disabled="false"
    >
      <transition-group type="transition" :name="!drag ? 'flip-list' : null">

        <tr v-for="(v,i) in sel_list"
            :key="'pl_'+v.id"
            class="Purchase__list_row"
            @mouseenter="$event.currentTarget.classList.add('Purchase__tblrow_hover')"
            @mouseleave="$event.currentTarget.classList.remove('Purchase__tblrow_hover')"
        >
          <td class="Purchase__list_no">
            <span class="Purchase__list_no_out">{{ i+1 }}</span>
            <span class="Purchase__list_no_hover">
                        <span class="Purchase__list_drag">
                          <svg viewBox="0 0 24 24"><path
                              d="M7,19V17H9V19H7M11,19V17H13V19H11M15,19V17H17V19H15M7,15V13H9V15H7M11,15V13H13V15H11M15,15V13H17V15H15M7,11V9H9V11H7M11,11V9H13V11H11M15,11V9H17V11H15M7,7V5H9V7H7M11,7V5H13V7H11M15,7V5H17V7H15Z"/></svg>
                        </span>
                        <button class="btn-remove" title="Remove"
                                @click="sel_list.splice(sel_list.findIndex((el)=>el.id == v.id), 1)">
                          <svg viewBox="0 0 24 24"><path
                              d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z"/></svg>
                        </button>
                      </span>

          </td>
          <td class="Purchase__list_desc" contenteditable="true">{{ v.description }}</td>
          <td class="Purchase__list_cat" contenteditable="true">{{ v.cat_ref_no }}</td>
          <td class="Purchase__list_unit" contenteditable="true">{{ v.unit}}</td>
          <td class="Purchase__list_ord" contenteditable="true">{{ v.quantity_ordered }}</td>
          <td class="Purchase__list_del" contenteditable="true">{{ v.quantity_delivered }}</td>
        </tr>
      </transition-group>
    </draggable>-->
  </div>
</template>
<script>
import UiTableHeaderCol from "@/views/UiTableHeaderCol";

export default {
  name: 'ui-table',
  components: {UiTableHeaderCol},
  props: {
    // dragable: {type: Boolean, default: true },
    // selectable: {type: Boolean, default: true },
    columns:{type: [Array,Object]},
    rows:{type: [Array,Object]},
    // sel_list: {type: Function}
  },
  methods:{
    styl(col){
      var s = {};
      if(col.width!==undefined){s.width=col.width;s['flex-grow']=0;}
      if(col.flex!==undefined){s['flex-grow']=col.flex;}
      return s;
    }
  },
}
</script>
<style lang="stylus">
.ui-table{
  *{box-sizing: border-box}
  $HeaderBorder = 1px solid #ccc;
  $BodyRowBorder = 1px solid #ccc;

  &__header{background:#ccc;display:flex;align-items: stretch;align-content: stretch;}
  &__header-col-place{display:flex;align-items: stretch;align-content: stretch;
    &:last-child>.ui-table__header-cols>.ui-table__header-children>.ui-table__header-col:last-child,
    &:last-child>.ui-table__header-cols>.ui-table__header-col:nth-child(1){border-right:$HeaderBorder;}
  }
  &__header-cols{display:flex;justify-content: stretch;align-items: stretch;align-content: stretch;flex-direction: column;
    //&>*:nth-child(1){border-left:0;}
    //&>*:last-child{border-right:0;}
  }
  &__header-col{background:#eee;border-top:$HeaderBorder; border-left:$HeaderBorder; display:flex;justify-content: center;align-items: center;align-content: center;flex-direction: column;}
  &__header-title{padding:3px 6px;}
  &__header-children{display:flex;flex-wrap: nowrap;align-items: stretch;align-content: stretch;border-top:$HeaderBorder;
    &>*{border-bottom:0;border-top:0;border-right:0;}
    //&>*:nth-child(1){border-left:0;}

  }

  &__row{display:flex;align-items: stretch;align-content: stretch;border-top:$BodyRowBorder;
    &:last-child{border-bottom:$BodyRowBorder;}
  }
  &__row-col{padding:3px 6px;border-left:$BodyRowBorder;
    &:last-child{border-right:$BodyRowBorder;}
  }
}

/*
i {
  display: inline-block;
  margin: 0;
  padding: 4px
}

input:checked + i {
  background-color: #ffeca6;
}

svg {
  display: block;
  width: 24px;
  height: 23px;
}

label:last-child {
  margin-right: 0
}



.Purchase__tbl {
  width: 100%;
  border-collapse: collapse;

  td, th {
    border: 1px solid #eee;
    padding: 3px 6px;
  }
  th{
    background: #f4f4f4;
    text-align: center;
  }
}
.Purchase__tblrow_hover td {
  background: #eee;
}

.Purchase__tblrow_sel td {
  background: #ffeba3;
}

//td.Purchase__dep{padding:0;width:50px}
//td.Purchase__dep div{width:50px;text-align:left;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;padding:3px 6px;}
td.Purchase__list_desc {
  position: relative;
  padding: 0;
  width: 30%;

  &, & div {
    white-space: normal;
    overflow: hidden;
    padding: 3px 6px;
  }
}

td.Purchase__list_cat {
  position: relative;
  padding: 0;
  width: 30%;

  &, & div {
    white-space: normal;
    overflow: hidden;
    padding: 3px 6px;
  }
}

td.Purchase__list_no {
  width: 25px;
  padding: 3px 0;
  text-align: center;
  background: #f4f4f4
}

td.Purchase__list_unit {
  width: 45px;
  padding: 3px 2px;
  text-align: center;
}

td.Purchase__list_ord {
  width: 45px;
  padding: 3px 2px;
  text-align: center;
}

td.Purchase__list_del {
  width: 45px;
  padding: 3px 2px;
  text-align: center;
}

//&:hover {
//  background-color: #ccc;
//  color: #000
//}
//
//&:hover {
//  background: transparent;
//  svg{fill: #e72e1b}
//}
//&:hover {
//  background: #29b70d;
//  svg{fill: #fff;}
//}
//margin:5px;
//border-radius:.25rem;
//}
.btn-remove {
  border: 0;
  outline: 0;
  vertical-align: middle;
  background: transparent;
  padding: 0;
  margin: 0;
  cursor: pointer;
  svg{
    display: block;
    fill: #B81C08;
    width: 24px;
    height: 24px
  }

  &:hover {
    background: transparent;
    svg{fill: #e72e1b}
  }
}
.Purchase__list_drag {
  display: inline-block;
  vertical-align: middle;
  background: transparent;
  padding: 0;
  margin: 0;
  cursor: move;
  svg{
    display: block;
    fill: #ccc;
    width: 24px;
    height: 24px
  }

  &:hover {
    background: transparent;
    svg{fill: #999
  }

}

.Purchase__list_no {
  min-width: 24px;
  min-height: 38px;
}

.Purchase__list_no_out {
  line-height: 24px;
  display: inline-block;
  vertical-align: middle;
}

.Purchase__tblrow_hover .Purchase__list_no_out, .Purchase__list_no_hover {
  display: none
}

.Purchase__tblrow_hover .Purchase__list_no_hover {
  display: inline-block;
}
*/
</style>
