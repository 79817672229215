<template>
  <div :class="{
                  'ui-table__header-col': col.children===undefined,
                  'ui-table__header-cols': col.children!==undefined,
                }"
       :style="styl"
  >
    <div class="ui-table__header-title" v-if="col.children===undefined">{{col.title}}</div>
    <template v-else>
      <div class="ui-table__header-col" :style="styl"><div class="ui-table__header-title">{{col.title}}</div></div>
      <div class="ui-table__header-children">
        <template v-for="(i,index) in col.children"  :key="i.id||index">
          <ui-table-header-col :col="i"/>
        </template>
      </div>
    </template>
  </div>
</template>
<script>
export default {
  name: 'ui-table-header-col',
  props: {
    col: {}
  },
  computed:{
    styl(){
      var s = {};
      if(this.col.width!==undefined){s.width=this.col.width;}
      if(this.col.flex!==undefined){s['flex-grow']=this.col.flex;}
      return s;
    }
  },
}
</script>
