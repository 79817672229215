<template>
  <Layouts2side lvl1="/vessels" width="300px" height="70%">
    <button class="btn-add" @click="selectVessel()">
      <span class="btn-icon"><svg><use xlink:href="#icon-plus"></use></svg></span>
      <span class="btn-text">Add Vessel</span>
    </button>

    <table cellspacing="0" class="left_tbl">
      <tr><th>Name of vessel</th><th>IMO</th><th>Official Number</th><th>GRT</th><th>Type of Ship</th><th>Classification Society</th><th>Flag</th><th>Port of Registry</th><th>Call Sign</th></tr>
      <tr v-for="v in purchase.vessels" :key="v.id" class="vessel" @click="selectVessel(v.id)"
          :class="{
            tr_sel: v.id==$route.params.id
          }"
      >
        <td>{{v.name}}</td>
        <td>{{v.imo}}</td>
        <td>{{v.number}}</td>
        <td>{{v.grt}}</td>
        <td>{{v.typeofship}}</td>
        <td>{{v.classification}}</td>
        <td>{{v.flag}}</td>
        <td>{{v.port}}</td>
        <td>{{v.callsign}}</td>
      </tr>
    </table>


    <template #wind>
      <!--      id:1,-->
      <div class="vessel_sets" v-if="$route.params.id!==undefined">
        <div class="flex">
          <div class="flex-sp"><Btn color="yellow" size="sm" :disabled="vesselChanged" @click="saveVessel" :class="{loading:loading}">Save</Btn></div>
          <div class="flex-sp" v-if="vessel_id!=='' && vessel_id!=='new'"><Btn color="red" :outline="true" size="sm" @click="deleteVessel" :class="{loading:loadingDel}">Delete</Btn></div>
        </div>

        <label>
          <div class="label_top">Name of vessel:</div>
          <input class="" v-model="name"/>
        </label>
        <label>
          <div class="label_top">IMO:</div>
          <input class="" v-model="imo"/>
        </label>
        <label>
          <div class="label_top">Official Number:</div>
          <input class="" v-model="number"/>
        </label>
        <label>
          <div class="label_top">GRT:</div>
          <input class="" v-model="grt"/>
        </label>
        <label>
          <div class="label_top">Type of Ship:</div>
          <input class="" v-model="typeofship"/>
        </label>
        <label>
          <div class="label_top">Classification Society:</div>
          <input class="" v-model="classification"/>
        </label>
        <label>
          <div class="label_top">Flag:</div>
          <input class="" v-model="flag"/>
        </label>
        <label>
          <div class="label_top">Port of Registry:</div>
          <input class="" v-model="port"/>
        </label>
        <label>
          <div class="label_top">Call Sign:</div>
          <input class="" v-model="callsign"/>
        </label>

      </div>

    </template>
  </Layouts2side>
</template>

<script>

import { mapState/*, mapActions*/ } from 'vuex';
import Layouts2side from "@/views/Layouts/Layouts2side";
import Btn from "@/components/ui/Btn";

export default {
  name: 'Vessels',
  components: {
    Btn,
    Layouts2side,
  },
  mounted() {
    if(this.$route.params.id!==undefined){
      this.selectVessel(this.$route.params.id);
    }
  },
  data:()=>({
    vessel_id:1,
    name:"",
    imo:"",
    number:"",
    grt:"",
    typeofship:"",
    classification:"",
    flag:"",
    port:"",
    callsign:"",

    vessel_sel:{},
    loading:false,
    loadingDel:false,
  }),
  computed: {
    ...mapState(['purchase']),
    lastLoginPrint(){
      return this.datetimePrint(this.last_login)
    },
    vesselChanged(){
      return this.vessel_id==='new'?false:(
          ( this.vessel_sel.name===this.name ) &&
          ( this.vessel_sel.imo===this.imo ) &&
          ( this.vessel_sel.number===this.number ) &&
          ( this.vessel_sel.grt===this.grt ) &&
          ( this.vessel_sel.typeofship===this.typeofship ) &&
          ( this.vessel_sel.classification===this.classification ) &&
          ( this.vessel_sel.flag===this.flag ) &&
          ( this.vessel_sel.port===this.port ) &&
          ( this.vessel_sel.callsign===this.callsign )
      );
    },
  },
  methods:{
    deleteVessel(){
      if(!this.loadingDel){
        let  this_=this;
        this_.loadingDel=true;
        this.$store.dispatch('EDIT',{all:[
            {action:'delete',page:'vessels',data:[this.vessel_id]},
          ],}).then((e)=>{this_.loadingDel=false;console.log('Deleted',e,this);this_.$router.push('/vessels')});
      }
    },
    saveVessel(){
      if(!this.loading){
        let  this_=this;
        this_.loading=true;
        this.$store.dispatch('EDIT',{all:[
          {action:'save',page:'vessels',data:[
              {
                id:               this.vessel_id,
                name:             this.name,
                imo:              this.imo,
                number:           this.number,
                grt:              this.grt,
                typeofship:       this.typeofship,
                classification:   this.classification,
                flag:             this.flag,
                port:             this.port,
                callsign:         this.callsign,
              },
            ]
          },
        ],}).then((e)=>{this_.loading=false;console.log('Saved',e,this);});
      }
    },
    selectVessel(id){
      let u;
      if(id!==undefined && id!=='new'){
        u=this.purchase.vessels.find((v)=>v.id-0===id-0);
        this.vessel_sel = u;
      }
      if(u!==undefined && id!=='new'){
        this.name=u.name;
        this.imo=u.imo;
        this.number=u.number;
        this.grt=u.grt;
        this.typeofship=u.typeofship;
        this.classification=u.classification;
        this.flag=u.flag;
        this.port=u.port;
        this.callsign=u.callsign;
        this.vessel_id=u.id;
      }else{
        this.name='';
        this.imo='';
        this.number='';
        this.grt='';
        this.typeofship='';
        this.classification='';
        this.flag='';
        this.port='';
        this.callsign='';
        this.vessel_id='new';
      }
      if(id!==undefined && this.$route.path!=='/vessels/'+id){
        this.$router.push('/vessels/'+id);
      }
      if(id===undefined && this.$route.path!=='/vessels/new'){
        this.$router.push('/vessels/new');
      }
    },
  },
}
</script>


<style lang="stylus">
.left_tbl{
  th{text-align:center;padding:3px 6px;}
  td{padding:3px 6px;cursor:pointer;}
  tr:hover td{background:#eee;}
  tr.tr_sel td{background:#FFEBA3;}
}


.vessel{
  &:hover{
    td{background:#eee;}
    .btn-icon{opacity:1;}
  }
  &_sel{background:#FFEBA3;}
}
.vessel_sets{padding:12px;
  label{display:block;padding:6px 0;}
  input:not([type="checkbox"]){display:block;width:100%;max-width:300px;}
}


</style>
