<template>
  <div class="home">
    <h1>Manual Categories</h1>

  </div>
</template>

<script>


export default {
  name: 'ManualCategories',
  components: {},
  // components: {}
}
</script>
