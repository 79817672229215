import { createStore } from 'vuex'
import purchase from '@/store/modules/purchase';


export default createStore({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {purchase,
  }
})
