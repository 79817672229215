<script>
/**
 * Выводит элемент с тегом заданным в атрибуте tag
 */
import {h} from 'vue';

export default {
  name: "Tag",
  props:{
    tag:{type:String,default:'span'}
  },
  render() {
    return h(
        this.tag, // tag name
        {}, // props/attributes
        this.$slots.default() // array of children
    )
  },
}
</script>

