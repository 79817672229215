
/**
 * СУПЕР СОРТИРОВКА.
 * Сортирует массив по человечески, по датам, по времени, по строкам, по числам и т.д.
 *
 * ОСОБЕННОСТЬ:
 * Массив может быть смешанным. Т.е. может сортировать, как строки так и числа.
 *
 * @param options {array}	Массив из параметров сортировки. Будет сортироваться последовательно по каждому параметру
 *                        	[
 *                        		{ 					// параметры
 *                        			key: 'a.b.c', 	// Ключ, по которому будет идти сортировка. Если пустой (по умолчанию), значит без ключей. Если задано через точки, значит показаны вложенные ключи
 *                        			asc: true,		// true - значит в прямом порядке сортировка будет, false - в обратном (по умолчанию true)
 *                        			as: 'natural'	// Как будет проводится операция сравнения (по умолчанию 'natural')
 *                        							// string 	- сравниваются как строки
 *                        							// number 	- сравниваются как числа
 *                        							// natural	- сравниваются как строки и числа в человеческом понимании по порядку. Т.е. "001" = "1", "013" > "2"
 *                        							// date 	- сравниваются как даты
 *                        							// datetime - сравниваются как даты и время
 *                        							// time 	- сравниваются как  время
 *                        		}
 *                        	]
 *
 * @example
 	 SuperSort(
     ['a2', 'a002.003ce', 'a01', '040ab', 'a002.003ce020',
   	 'a', 11, 'a02.04', 'a002.03', '01ab', 'a002.003', 5,
   	 'a002.003a', 'a002.003ca', '00ab', 'a002.003c', 'a002.003cd',
   	  0, 'a002.003cb', 'a002.003', 'a002.003ce10', 'a002.003b'
   	])

 * @example
 SuperSort(
  [ {c:'a02a10a', a:'a01',b:10},	{c:'a02a10a',a:'a09',b:10}, {c:'a02a10a', a:'a1',b:10}, {c:'a02a04a', 	a:'1b',b:20},
	  {c:'a02a10a', a:'a2',b:10},	{c:'a02a1a', a:'a',b:10}, 	{c:'a02a01a', a:'a',b:10},	{c:'a02a01a', 	a:'a',b:0},
	  {c:'a02a10a', a:'a',b:10},	{c:'a02a01a',a:'a',b:10}, 	{c:'a02a01a', a:'a',b:20},	{c:'a02a10a', 	a:'ab',b:10},
	  {c:'a02a01a', a:'a',b:1},		{c:'a02a10a',a:'a10',b:10},
	],[
			{key:'c',asc:true,as:'natural'},
			{key:'a',asc:true,as:'string'},
			{key:'b',asc:true,as:'number'},
		])

 * @example
 SuperSort(
  [ ['a02a10a', 'a01',10],	['a02a10a','a09',10],   ['a02a10a','a1',10], 	['a02a04a', '1b', 20],
	  ['a02a10a', 'a2',10],		['a02a1a', 'a',	 10], 	['a02a01a','a', 10],	['a02a01a', 'a',  0 ],
	  ['a02a10a', 'a',10],		['a02a01a','a',	 10], 	['a02a01a','a', 20],	['a02a10a', 'ab', 10],
	  ['a02a01a', 'a',1],		['a02a10a','a10',10],
	],[
				{key:2,asc:true,as:'natural'},
				{key:0,asc:true,as:'string'},
				{key:1,asc:true,as:'number'},
			]);

 * @example
  SuperSort(
  [ ['a02a10a'],	['a02a0010a'],	['a02a10a'], 	['a02a04a'  ],
	  ['a02a00a'],	['a02a1a'   ], 	['a02a01a'],	['a02a0001a'],
	  ['a02a12a'],	['a02a001a' ], 	['a02a21a'],	['a02a2a'   ],
	  ['a02a01a'],	['a02a02a'  ],
	],[
				{key:0,asc:true,as:'natural'},
			]);
 */

export default function SuperSort(arr,options){
	if (arr == null) {return false;} 								// Ошибка если массив не существует
	var opt=options==undefined?[{key:'',asc:true,as:'natural'}]:options;	// Определяем значения по умолчанию для сортировки ключей

	function convert2date(d,date_or_time_or_datetime){
		if(date_or_time_or_datetime=='time'){
			var re_time=/^\D*((2[0-3]|[0-1][0-9]|\d):([0-5][0-9])(?::([0-5][0-9](?:\.[0-9]+)?))?)\D*$/i;
			return typeof t == 'string'?
						(t.replace(re_time,function(str,p1,p2,p3,p4){
							return ((p2*60+p3)*60+p4)*1000;
						})-0)
					:
						((d.getHours()*60+d.getMinutes())*60+d.getSeconds())*1000+d.getMilliseconds()
					;
		}
		var t= new Date(Date.parse(d));
		return date_or_time_or_datetime=='date'?(new Date(t.toISOString().substring(0,10))): t ;
	}

	function _sort(x, y) { // сама функция сортировки
		if(x==y){return 0;} // для скорости, когда объекты 100% идентичные
		function split_numbers(str){
			var h,k,as,asc,val=[];
			for (var i = 0; i < opt.length; i++) {
				as = opt[i].as||'natural';
				k=opt[i].key===''||opt[i].key===undefined?str:( eval( 'str'+(opt[i].key+'').split('.').map(function(v){return '["'+v+'"]'}).join('') ) );
				asc=opt[i].asc==undefined?true:opt[i].asc;	// Порядок сортировки, по умолчанию в порядке возрастания
				h={
					a:asc,
					v:as =='string'?([''+k]):(			// Как строка
						as =='number'?([k-0]):(		// Как число
							as =='natural'?			// Натуральная сортировка
								typeof k=="number"?[k]:k.split(/(\d+(?:\.\d+)?)/ig).filter(function(v){return v!='';}).map(function(v){return /^[\d\.]+$/i.test(v)?(v-0):v;})
								:
								(
									as == 'date' || as == 'time' || as == 'datetime' ?([convert2date(k,as)]):['']
								)
						)
					)
				};
				val.push(h);
			}
			return val;
		}
		var a=split_numbers(x),
			b=split_numbers(y),
			len,len_b,asc;
		if(a==b){return 0;} // Для скорости. Когда идет сортировка по ключам, и ключи идентичны.
		for (var i = 0; i < a.length; i++) {
			len   = a[i].v.length;
			len_b = b[i].v.length;
			asc   = a[i].a?1:-1;
			for (var k = 0; k < len; k++) {
				if(b[i].v[k]===undefined){return 1*asc;}
				if(typeof a[i].v[k]=='string' && typeof b[i].v[k]=='number'){return 1*asc;}
				if(typeof a[i].v[k]=='number' && typeof b[i].v[k]=='string'){return -1*asc;}
				if (a[i].v[k] > b[i].v[k]) {return  1*asc;}
				if (a[i].v[k] < b[i].v[k]) {return -1*asc;}
			}
			if(len==len_b){continue}
			if(len<len_b){return -1*asc;}
			if(len>len_b){return 1*asc;}
		}
		return 0;

	}
	return arr.sort(_sort);
};
