<template>
  <div class="layout2side"
       :class="{
          layout2side_horiz: layout_===0,
          layout2side_vert:  layout_===1,
          layout2side_wind:  layout_===2,
          layout2side_fullwind: full,
       }"
  >
    <div class="left" :style="blLeftStyle">
      <div class="btnBl"
           title="Window Views"
           @mouseover="delayHover('hovered','hovered_')"
           @mouseout="delayOut('hovered','hovered_',100)"
      >
        <transition-group name="list">
          <label class="change" v-for="i in filteredLayouts" :key="'layout'+i[0]">
            <input v-model="layout_" type="radio" :value="i[0]">
            <i><svg><use :xlink:href="'#'+i[1]"></use></svg></i>
          </label>
        </transition-group>
      </div>
      <div class="layout2side__vp1">
        <slot></slot>
      </div>
    </div>
    <div class="right" :style="blRightStyle" v-show="windEnable">
      <div class="btnBl">
        <span class="full" @click="full=!full"><svg><use xlink:href="#icon-fullwind"></use></svg></span>
        <span class="close" @click="$router.push(lvl1)"><svg><use xlink:href="#icon-close"></use></svg></span>
      </div>
      <div class="layout2side__vp2" v-if="windEnable">
        <slot name="wind"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Layouts2side",
  props:{
    lvl1: {  type:String, default: '/pur'},
    layout:{ type:Number, default: 0 },
    width: { type:String, default: '60%'}, // Ширина блока редактирования
    height: {type:String, default: '60%'}, // Высота блока редактирования
    between:{type:String, default: '3px'},
  },
  emits:['update:layout'],
  mounted() {
    this.layout_=this.layout;
  },
  data:()=>({
    layout_:0,
    full:false,
    hovered:false,
    hovered_:0,
    layouts:[
      [0,'icon-view-vert',],
      [1,'icon-view-horiz',],
      [2,'icon-view-wind',],
    ],
  }),
  methods:{
    delayHover(var1,var2){
      this[var1]=true;
      this[var2]++;
    },
    delayOut(var1,var2,time){
      setTimeout(()=>{
        this[var2]--;
        if(this[var2]<=0){this[var1]=false;}
      },time)
    },
  },
  watch:{
    layout_(newValue){this.$emit('update:layout', newValue);},
  },
  computed:{
    filteredLayouts(){
      return this.hovered
             ?(this.layouts)
             :(this.layouts.filter( i => i[0]==this.layout_ ));
    },
    windEnable(){
      console.log('Слот',this.lvl1, this.$route.path,this.lvl1 === this.$route.path);
      // return this.$slots.wind!==undefined && this.$slots.wind!==null
      return this.lvl1 !== this.$route.path
    },
    blLeftStyle(){
      if(this.full || !this.windEnable){
        return {top: 0, left: 0, bottom: 0, right: 0};
      } else if (this.layout_===0){// по вертикали рядом
        return {top:0, left:0, bottom:0, right: 'calc('+this.width+')',};
      } else if (this.layout_===1){ // один под другим
        return {top:0, left:0, bottom: 'calc('+this.height +')', right:0};
      }else{
        return {top: 0, left: 0, bottom: 0, right: 0};
      }
    },
    blRightStyle(){
      if(!this.windEnable){
        return {display:'none', top: '0', left: '0', bottom: '0', right: '0',};
      }else if(this.full){
        return {top: '0', left: '0', bottom: '0', right: '0',};
      } else if (this.layout_===0){ // по вертикали разбиты
        return {top:0, left:'calc(100% - '+this.width + ' + ' + this.between+')', bottom:0, right:0 ,};
      } else if (this.layout_===1){ // один под другим
        return {top: 'calc(100% - '+this.height+' + ' + this.between + ')' , left:0, bottom: 0, right:0};
      }else{
        return {top: '10%', left: '10%', bottom: '10%', right: '10%', boxShadow:'0 5px 15px 0 rgba(0,0,0,.5)'};
      }
    },
  },
}
</script>

<style scoped lang="stylus">
.layout2side{transition: all ease 0.6s;min-height:200px;position:relative;}
.left{ border:1px solid #ccc;transition: all ease 0.6s;position:absolute;overflow:auto;scrollbar();}
.right{border:1px solid #ccc;transition: all ease 0.6s;position:absolute;overflow:auto;scrollbar();z-index:1;background:#fff;FullHeight();}

.layout2side__vp2{
  FullHeight();
}

.btnBl{position:absolute;top:0;right:0;}
.right .btnBl{z-index:2;}

.full,.close{width:24px;height:18px;display:inline-block;vertical-align:top;text-align:center;
  svg{width:16px;height:16px;margin:-1px 0 1px 0;opacity:0.3}
  &:hover{background-color:rgba(0,0,0,.2);
    svg{opacity:1;fill:#fff}
  }
}
.close{
  svg{margin:0;}
  &:hover{background-color:#f99;}
}
.change{
  transition: all 0.8s ease;
  vertical-align:middle;
  display:block;
  cursor:pointer
  input{display:none;}
  i{display:inline-block;margin:0;padding:4px}
  input:checked + i{background-color: #ffeca6;}
  svg{display:block;width:24px;height:23px;}
}

.list-enter-active,
.list-leave-active {
  transition: all 1s ease;
}
.list-leave-active{position: absolute;}
.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: translateX(16px) scale(0);
  //width:0;
}
</style>
