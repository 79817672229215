<template>
  <div class="home">
    <h1>Ship operations</h1>
  </div>
</template>

<script>
export default {
  name: "ShipOperations"
}
</script>

<style scoped>

</style>
