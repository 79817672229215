<template>
  <div class="home">
    <h1>Certification</h1>
  </div>
</template>

<script>
export default {
  name: "Certification"
}
</script>

<style scoped>

</style>
