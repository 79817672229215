<template>
  <div class="Dashboard">
    <div class="MainMenu">
      <router-link class="MainMenu__li"
                   v-for="(s,k) in purchase.menu"
                   :key="k"
                   :to="s.to"
      >
        <span class="MainMenu__icon"><svg><use :xlink:href="'#'+s.icon"></use></svg></span>
        <div>
          <span v-html="s.short"></span> <span  class="MainMenu__news" v-if="s.news.length>0">NEW +{{s.news.length}}</span>
          <span  class="MainMenu__edited" v-if="s.edited.length>0">EDIT +{{s.edited.length}}</span>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
import { mapState/*, mapActions*/ } from 'vuex';

export default {
  name: "Dashboard",
  computed: {
    ...mapState(['purchase']),

  },
}
</script>

<style lang="stylus">
.MainMenu{display:flex;flex-wrap: wrap}
.MainMenu__icon{width:3rem;height:3rem;display:inline-block;
  svg{width:3rem;height:3rem;display:inline-block;fill:#007bff;transition:all ease-in-out 0.15s;}
}
.MainMenu__li{
  text-decoration:none;
  margin:0.5rem;
  flex:1 0 27%;
  min-width:150px;
  font-size: 125%;
  line-height: 1.5;
  border-radius: .3rem;
  color: #007bff;
  display: inline-block;
  font-weight: 400;
  text-align: center!important;
  vertical-align: middle;
  background-color: transparent;
  border: 1px solid #007bff!important;
  padding: 1rem .25rem .375rem .25rem;
  cursor:pointer;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  &:hover,&.router-link-active{
    color: #fff!important;
    background-color: #007bff!important;
    border:1px solid #007bff!important;
    svg{fill:#fff;}
  }

}


.MainMenu__news,.MainMenu__edited{background:#c00;color:#fff;font-size:60%;padding:0.3em 0.6em;border-radius:30px;vertical-align:middle;margin:3px;white-space: nowrap;}
.MainMenu__edited{background:#090;}
</style>
