import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Manuals from "../views/Manuals";
import Certification from "../views/Certification";
import PersonnelManagement from "../views/PersonnelManagement";
import Purchase from "../views/Purchase";
import PlannedMaintenance from "../views/PlannedMaintenance";
import Emergency from "../views/Emergency";
import ShipOperations from "../views/ShipOperations";
import SystemVerifications from "../views/SystemVerifications";
import Reporting from "../views/Reporting";
import Users from "@/views/Settings/Users";
import Vessels from "@/views/Settings/Vessels";
import MachineryList from "@/views/Settings/MachineryList";
import ManualCategories from "@/views/Settings/ManualCategories";
import Positions from "@/views/Settings/Positions";
// import AccessGroups from "@/views/Settings/AccessGroups";
import Wage from "@/views/Settings/Wage";

const routes = [
  {path: '/',    name: 'Home',                                        component: Home},
  {path: '/mnl', name: 'Manuals',                                     component: Manuals},
  {path: '/crt', name: 'Certification',                               component: Certification},
  {path: '/prs', name: 'Employees'/*'Personnel management'*/,         component: PersonnelManagement,
    children: [ {path: '/prs/:id', name: 'Employee',                  component: PersonnelManagement},],
  },
  {path: '/pur', name: 'Purchase',                                    component: Purchase,
    children: [ {path: '/pur/:id', name: 'Purchase ID',               component: Purchase},],
  },
  {path: '/pln', name: 'Planned maintenance',                         component: PlannedMaintenance},
  {path: '/emr', name: 'Emergency preparedness Training and drills',  component: Emergency},
  {path: '/shp', name: 'Ship operations',                             component: ShipOperations},
  {path: '/vrf', name: 'System verifications',                        component: SystemVerifications},
  {path: '/rep', name: 'Reporting System Follow Up Control',          component: Reporting},

  {path: '/users', name: 'Users',                                     component: Users,
    children: [
        {path: '/users/:id', name: 'User details',                    component: Users},
        {path: '/users/ag/:ag', name: 'Access Group',                 component: Users},
    ],
  },
  // {path: '/access', name: 'Access Groups',                            component: AccessGroups,
  //   children: [ {path: '/access/:id', name: 'Access Groups',          component: AccessGroups},],
  // },
  {path: '/vessels', name: 'Vessels',                                 component: Vessels,
    children: [ {path: '/vessels/:id', name: 'Vessel View',               component: Vessels},],
  },
  {path: '/machinery', name: 'Machinery List',                           component: MachineryList,
    children: [ {path: '/machinery/:id', name: 'Machinery Item View',    component: MachineryList},],
  },
  {path: '/manual-cat', name: 'Manual Categories',                    component: ManualCategories,
    children: [ {path: '/manual-cat/:id', name: 'Manual Category View',  component: ManualCategories},],
  },
  {path: '/positions', name: 'Positions',                             component: Positions,
    children: [ {path: '/positions/:id', name: 'Position View',       component: Positions},],
  },
  {path: '/wage', name: 'ITF Wage Scale & COE',                       component: Wage,
    children: [ {path: '/wage/:id', name: 'ITF Wage Scale & COE View',component: Wage},],
  },
  // {path: '/about', name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
