<!--

    <DropDown
        hover-ref=".sel1,.sel2,.sel3" // элементы (css селектором) через запятую,
                                   // ПРИ НАВЕДЕНИИ на которые, будет выпадать окно
                                  // click-ref и hover-ref одновременно не устанавливать. Иначе неоднозначно работает
        click-ref="[.sel]"   // элемент или массив элементов, ПРИ КЛИКЕ на которые, будет выпадать окно
        focus-ref="inp"   //
        element=".sel"       // Показывает относительно какого элемента считать координаты
    >
      aaaaa
    </DropDown>

-->

<template>
  <teleport to="body">
    <div class="DropDown"
         :style="ddStyle"
         @mouseenter="mouse++"
         @mouseleave="mouse_minus()"
         ref="dd"
         v-if="mouse>0"
         @click="log('DropDown:@click',$event);$emit('click',$event)"
         @dblclick="$emit('dblclick',$event)"
    >
      <slot name="default"><div class="DropDown__empty">Пусто</div></slot>
    </div>
  </teleport>
</template>

<script>
import {MaxZindexOnPage} from "mylib/MaxZindexOnPage.js";

export default {
  name: "DropDownClassSelector",
  emits:['click','dblclick'],
  props:{
    position:{type:String,default:'d'},
    arrow:{type:Boolean, default:false},
    hoverRef:{type:[String,Object]},
    focusRef:{type:String},
    clickRef:{type:String},
    element:{type:[String,Object]},
    width:{type:[String,Number]},
    outDelay:{type:Number, default:100}, // Задержка убирания меню в милисекундах
  },
  data(){
    return {
      ddStyle:{},
      hoverList:[],
      focusList:[],
      el:null,
      mouse:0,
    }
  },
  mounted() {
    let this_= this;
    this.el=!this.element?undefined:(typeof(this.element)=='string'?(this.$parent.$el.querySelectorAll(this.element)):(this.element));

    if(this.hoverRef!==undefined && this.hoverRef!==null && this.hoverRef!==''){
      let Hoverlist = this.$parent.$el.querySelectorAll(this.hoverRef);//this.hoverRef.split(',').map( (el) => this_.$parent.$refs[el] );
      this.hoverList=Hoverlist;
      console.log('DropDown:el '+this.hoverRef,Hoverlist);
      if(this.el===undefined || this.el==='' || this.el===null ){this.el=Hoverlist[0];}
      Array.prototype.slice.call(Hoverlist).forEach(function(el){
        // Показывать или не показывать сплывашку.
        // Принцип такой, каждый hover увеличивает счетчик на 1, а out уменьшает, но на 100 милисекунд позже
        // console.log('DropDown:el '+this_.hoverRef,el);
        try {
          el.addEventListener('mouseenter', function () {
            el.classList.add('DropDown__menu_hover');
            // console.log('DropDown:hover '+this_.hoverRef);
            this_.setDDStyle(this_.el);
            this_.mouse++;
          });
          el.addEventListener('mouseleave', function () {
            setTimeout(function () {
              this_.mouse_minus();
            }, this_.outDelay);
          });
        }catch(e){console.log('DropDown Error',e);}
      });

    }



/*
    if(this.clickRef!==undefined && this.clickRef!==null && this.clickRef!==''){
      // todo возможно стоит переделать, чтобы при blur убирался фокус, и меню скрывалось вне зависимости от Mouseover
      let clicklist = this.clickRef.split(',').map( (el) => this_.$parent.$refs[el] );
      if(this.el==undefined || this.el=='' || this.el==null ){this.el=clicklist[0];}
      this.clickList=clicklist;

      // Показывать или не показывать сплывашку.
      // Принцип такой, каждый hover увеличивает счетчик на 1, а out уменьшает, но на 100 милисекунд позже
      const onClickOutside = (e) => {
        try{
          if( clicklist.some( (el) => el.contains(e.target) ) || this_.$refs.dd.contains(e.target) ) {
            this_.mouse=1;
          }else{
            this_.mouse=0;
          }
          // eslint-disable-next-line no-empty
        }catch(e){}
      };
      document.addEventListener('click', onClickOutside);
      document.addEventListener('dblclick', onClickOutside);

      // el.addEventListener('click',function(){this_.setDDStyle(this_.el); this_.mouse++;});
    }
    */
/*

    if(this.focusRef!==undefined && this.focusRef!==null && this.focusRef!==''){
      // todo возможно стоит переделать, чтобы при blur убирался фокус, и меню скрывалось вне зависимости от Mouseover
      let Focuslist = this.focusRef.split(',').map( (el) => this_.$parent.$refs[el] );
      if(this.el==undefined || this.el=='' || this.el==null ){this.el=Focuslist[0];}
      this.focusList=Focuslist;
      Focuslist.forEach(function(el){
        // Показывать или не показывать сплывашку.
        // Принцип такой, каждый hover увеличивает счетчик на 1, а out уменьшает, но на 100 милисекунд позже
        el.addEventListener('focus',function(){this_.setDDStyle(this_.el); this_.mouse++;});
        el.addEventListener('blur',function(e){
          // if( this_.$refs.dd.contains(e.relatedTarget))
          setTimeout(function(){this_.mouse--;},this_.outDelay);
          console.log('focus blur=',e);
          // this_.mouse=0;
        });
      });
    }
*/

    this_.setDDStyle(this.el);
  },
  methods: {

    log(){
      console.log('log',arguments);
    },
    mouse_minus(){
      let this_=this;
      setTimeout(function () {
        // console.log('DropDown:out '+this_.hoverRef);
        this_.mouse--;
        if(this_.mouse<=0){
          let hov=this_.$parent.$el.querySelectorAll(this_.hoverRef);
          if(hov.length>0) {
            hov.forEach(el => {
              el.classList.remove('DropDown__menu_hover');
            })
          }
        }
      }, this_.outDelay);
    },
    /**
     * Устанавливает координаты выпадающего меню относительно HTML элемента el
     *
     * @param el
     */
    setDDStyle(el) {
      // eslint-disable-next-line no-debugger
      // debugger;

      // console.log('this_.element',el);
      if (el !== undefined && el !== null) {


        var select_coord = el.getClientRects()[0];
        var center_w = select_coord.left + Math.round(select_coord.width / 2);
        var left = select_coord.left;
        var width = select_coord.width;
        if(this.width){
          left = center_w - this.width/2;
          width = this.width;
        }
        this.ddStyle = {
          left: left + 'px',
          maxWidth: width + 'px',
          maxHeight: '60vh',
          top: (select_coord.top + select_coord.height + document.querySelector('html').scrollTop) + 'px',
          position: 'absolute',
          zIndex: MaxZindexOnPage() + 1
        };
      }
    },
  }
}
</script>

<style lang="stylus">
.DropDown{
  border:1px solid #ccc;
  background:#fff;
  cursor:default;
  overflow:auto;
  //box-shadow:0 5px 15px -5px rgba(0,0,0,.5);
  box-shadow: 0 10px 20px -5px rgba(0,0,0,.4);
}
.DropDown__empty{padding:40px;color:#ccc;}
</style>
