<template>
  <button class="Burger">
    <div class="Burger__ico">
      <div class="Burger__l"></div>
      <div class="Burger__l"></div>
      <div class="Burger__l"></div>
    </div>
  </button>
</template>

<script>
export default {
  name: "Burger"
}
</script>

<style lang="stylus" scoped>
.Burger{
  border:0;
  padding:0;
  margin:0;
  min-width:24px;
  min-height:24px;
  cursor: pointer;
  background:transparent;
  outline:none;
  &__ico{
    margin:0 auto;
    width: 20px;
    height: 15px;
  }
  &__l{
    width: 100%;
    height: 3px;
    margin: 0 0 3px;
    transition: background .3s;
    border-radius: 1.5px;
    background: #858585;
  }
  &:hover \&__l{background:rgb(0, 102, 255);}
  &:hover \&__l:nth-child(1){ animation: Burger-anim .7s linear .245s;}
  &:hover \&__l:nth-child(2){ animation: Burger-anim .7s linear 0s;}
  &:hover \&__l:nth-child(3){ animation: Burger-anim .7s linear .56s;}
}
@keyframes Burger-anim {
  0%,to { width: 100%}
  50% { width: 0}
}
</style>
