<template>
  <Layouts2side lvl1="/machinery" width="300px" height="40%">
    <div class="flex" style="padding-right:100px">
      <button class="btn-add" @click="selectItem()">
        <span class="btn-icon"><svg><use xlink:href="#icon-plus"></use></svg></span>
        <span class="btn-text">Add</span>
      </button>
      <Btn color="yellow" @click="saveOrder()" v-if="isNeedSaveOrder" :class="{loading:loadingOrder}">Save Order</Btn>
      <div class="flex-sp"></div>
      <select v-model="chooseVessel">
        <option v-for="v in purchase.vessels" :key="v.id" :value="v.id">{{v.name}}</option>
      </select>
    </div>
    <table cellspacing="0" class="left_tbl">
      <tr><th><svg><use xlink:href="#icon-sort"></use></svg></th><th>Kind of Engine</th><th>Maker</th><th>Engine Type</th><th>Engine No or S/N</th></tr>
      <draggable
          v-model="purchase.machinery"
          tag="tbody"
          draggable=".left_tbl__row"
          handle=".DragHand"
          ghost-class="DragHand_ghost"
          v-bind="{
                          animation:600,
                          group: 'description',
                          // onChange:function(){$emit('update');},
                          // onEnd:function(){$emit('update');},
                        }"
          @start="drag = true;"
          @end="drag = false; isNeedSaveOrder=true;"
          :disabled="false"
      >
        <transition-group type="transition" :name="!drag ? 'flip-list' : null">
          <template v-for="v in purchase.machinery" :key="v.id">
            <tr  class="left_tbl__row" @click="selectItem(v.id)"
                :class="{
                  tr_sel: v.id===$route.params.id
                }"
                 v-if="v.vessel_id===chooseVessel"
            >
              <td><span class="DragHand"></span></td>
              <td>{{v.kind}}</td>
              <td>{{v.maker}}</td>
              <td>{{v.type}}</td>
              <td>{{v.number}}</td>
            </tr>
          </template>
        </transition-group>
      </draggable>
    </table>

    <template #wind>
      <!--      id:1,-->
      <div class="sets" v-if="$route.params.id!==undefined">
        <div class="flex">
          <div class="flex-sp"><Btn color="yellow" size="sm" @click="save()" :disabled="itemChanged" :class="{loading:loading}">Save</Btn></div>
          <div class="flex-sp" v-if="item_id!=='' && item_id!=='new'"><Btn color="red" :outline="true" size="sm" @click="del()" :class="{loading:loadingDel}">Delete</Btn></div>
        </div>

        <label>
          <div class="label_top">Kind of Engine:</div>
          <input class="" v-model="kind"/>
        </label>
        <label>
          <div class="label_top">Maker:</div>
          <input class="" v-model="maker"/>
        </label>
        <label>
          <div class="label_top">Engine Type:</div>
          <input class="" v-model="type"/>
        </label>
        <label>
          <div class="label_top">Engine No or S/N:</div>
          <input class="" v-model="number"/>
        </label>
<!--        <label>
          <div class="label_top">Vessel:</div>
          <input class="" v-model="vessel_id"/>
        </label>-->
      </div>

    </template>
  </Layouts2side>
</template>

<script>

import { mapState/*, mapActions*/ } from 'vuex';
import Layouts2side from "@/views/Layouts/Layouts2side";
import Btn from "@/components/ui/Btn";
import {VueDraggableNext} from 'vue-draggable-next'

export default {
  name: 'MachineryList',
  components: {
    Btn,
    Layouts2side,
    draggable:VueDraggableNext,
  },
  mounted() {
    if(this.$route.params.id!==undefined){
      this.selectItem(this.$route.params.id);
    }
  },
  data:()=>({
    drag:false,
    isNeedSaveOrder:false,
    chooseVessel:1,

    item_id:1,
    kind: "",
    maker: "",
    type: "",
    number: "",
    vessel_id: 0,

    item_sel:{},
    loading:false,
    loadingDel:false,
    loadingOrder:false,
  }),
  computed: {
    ...mapState(['purchase']),

    itemChanged(){
      return this.item_id==='new'?false:(
          ( this.item_sel.kind===this.kind ) &&
          ( this.item_sel.maker===this.maker ) &&
          ( this.item_sel.type===this.type ) &&
          ( this.item_sel.number===this.number ) &&
          ( this.item_sel.vessel_id===this.vessel_id )
      );
    },
  },
  methods:{
    saveOrder(){
      if(this.isNeedSaveOrder){
        let  this_=this;
        this_.loadingOrder=true;
        let ord = 0;
        this.$store.dispatch('EDIT',{all:[
            {action:'save',page:'machinery',data:this.purchase.machinery.map(e => { ord++;return { id:e.id,ord:ord }; } )},
          ],}).then((e)=>{this_.isNeedSaveOrder=false;this_.loadingOrder=false;console.log('Save Order',e,this);this_.$router.push('/machinery')});
      }
    },
    del(){
      if(!this.loadingDel){
        let  this_=this;
        this_.loadingDel=true;
        this.$store.dispatch('EDIT',{all:[
            {action:'delete',page:'machinery',data:[this.item_id]},
          ],}).then((e)=>{this_.loadingDel=false;console.log('Deleted',e,this);this_.$router.push('/machinery')});
      }
    },
    save(){
      if(!this.loading){
        let  this_=this;
        this_.loading=true;
        this.$store.dispatch('EDIT',{all:[
            {action:'save',page:'machinery',data:[
                {
                  id:               this.item_id,

                  kind:             this.kind,
                  maker:            this.maker,
                  type:             this.type,
                  number:           this.number,
                  vessel_id:        this.chooseVessel,
                },
              ]
            },
          ],}).then((e)=>{
            this_.loading=false;
            console.log('Saved',e,this);
            this_.item_sel=this_.purchase.machinery.find((v)=>v.id-0===this_.item_id-0);
          });
      }
    },

    selectItem(id){
      let u;
      if(id!==undefined || id!=='new'){
        u=this.purchase.machinery.find((v)=>v.id-0===id-0);
        this.item_sel = u;
      }
      if(u!==undefined || id==='new'){
        this.kind=u.kind;
        this.maker=u.maker;
        this.type=u.type;
        this.number=u.number;
        this.vessel_id=u.vessel_id;
        this.item_id=u.id;
      }else{
        this.kind='';
        this.maker='';
        this.type='';
        this.number='';
        this.vessel_id='';
        this.item_id='new';
      }
      if(id!==undefined && this.$route.path!=='/machinery/'+id){
        this.$router.push('/machinery/'+id);
      }
      if(id===undefined && this.$route.path!=='/machinery/new'){
        this.$router.push('/machinery/new');
      }
    },
  },
}
</script>


<style lang="stylus">
.left_tbl{
  &>tr>td,&>tbody>tr>td{.DragHand{width:20px;height:1.56rem;display:inline-block;vertical-align:middle;opacity:0;}}
  &>tr:hover>td,&>tbody>tr:hover>td{.DragHand{opacity:1;}}
  th{text-align:center;padding:3px 6px;
    svg{width:20px;height:20px;}
  }
  &>tr>td,&>tbody>tr>td{padding:3px 6px;cursor:pointer;}
  &>tr:hover>td,&>tbody>tr:hover>td{background:#eee;}
  &>tr.tr_sel>td,&>tbody>tr.tr_sel>td{background:#FFEBA3;}
}

.sets{padding:12px;
  label{display:block;padding:6px 0;}
  input:not([type="checkbox"]){display:block;width:100%;max-width:300px;}
}
</style>
