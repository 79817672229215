<template>
  <Layouts2side lvl1="/positions" width="300px" height="40%">
    <button class="btn-add" @click="selectPosition()">
      <span class="btn-icon"><svg><use xlink:href="#icon-plus"></use></svg></span>
      <span class="btn-text">Add Position</span>
    </button>
    <Btn color="yellow" @click="saveOrder()" v-if="isNeedSaveOrder" :class="{loading:loadingOrder}">Save Order</Btn>

    <table cellspacing="0" class="left_tbl">
      <tr><th><svg><use xlink:href="#icon-sort"></use></svg></th><th>Position</th><th>Alternative</th><th>Active</th></tr>
      <draggable
          v-model="purchase.positions"
          tag="tbody"
          draggable=".left_tbl__row"
          handle=".DragHand"
          ghost-class="DragHand_ghost"
          v-bind="{
                          animation:600,
                          group: 'description',
                          // onChange:function(){$emit('update');},
                          // onEnd:function(){$emit('update');},
                        }"
          @start="drag = true;"
          @end="drag = false; isNeedSaveOrder=true;"
          :disabled="false"
      >
        <transition-group type="transition" :name="!drag ? 'flip-list' : null">

          <tr v-for="v in purchase.positions" :key="v.id" class="left_tbl__row" @click="selectPosition(v.id)"
              :class="{
                tr_sel: v.id===$route.params.id
              }"
          >
            <td><span class="DragHand"></span></td>
            <td>{{v.name}}</td>
            <td>{{v.alternative}}</td>
            <td>{{v.active?'Yes':'No'}}</td>
          </tr>

        </transition-group>
      </draggable>
    </table>

    <template #wind>
      <!--      id:1,-->
      <div class="sets" v-if="$route.params.id!==undefined">
        <div class="flex">
          <div class="flex-sp"><Btn color="yellow" size="sm" :disabled="vesselChanged" @click="save()" :class="{loading:loading}">Save</Btn></div>
          <div class="flex-sp" v-if="item_id!=='' && item_id!=='new'"><Btn color="red" :outline="true" size="sm" @click="del()" :class="{loading:loadingDel}">Delete</Btn></div>
        </div>

        <label>
          <div class="label_top">Position:</div>
          <input class="" v-model="name"/>
        </label>
        <label>
          <div class="label_top">Alternative:</div>
          <input class="" v-model="alternative"/>
        </label>
        <label>
          <input class="" v-model="active" type="checkbox" :true-value="1" :false-value="0"/> Active
        </label>
      </div>

    </template>
  </Layouts2side>
</template>

<script>

import { mapState/*, mapActions*/ } from 'vuex';
import Layouts2side from "@/views/Layouts/Layouts2side";
import Btn from "@/components/ui/Btn";
import {VueDraggableNext} from 'vue-draggable-next'

export default {
  name: 'Positions',
  components: {
    Btn,
    Layouts2side,
    draggable:VueDraggableNext,
  },
  mounted() {
    if(this.$route.params.id!==undefined){
      this.selectPosition(this.$route.params.id);
    }
  },
  data:()=>({
    drag:false,
    isNeedSaveOrder:false,

    item_id:1,
    name:"",
    alternative:"",
    active:false,

    item_sel:{},

    loading:false,
    loadingDel:false,
    loadingOrder:false,
  }),
  computed: {
    ...mapState(['purchase']),

    vesselChanged(){
      return this.item_id==='new'?false:(
          ( this.item_sel.name===this.name ) &&
          ( this.item_sel.alternative===this.alternative ) &&
          ( this.item_sel.active===this.active )
      );
    },
  },
  methods:{
    saveOrder(){
      if(this.isNeedSaveOrder){
        let  this_=this;
        this_.loadingOrder=true;
        let ord = 0;
        this.$store.dispatch('EDIT',{all:[
            {action:'save',page:'positions',data:this.purchase.positions.map(e => { ord++;return { id:e.id,ord:ord }; } )},
          ],}).then((e)=>{this_.isNeedSaveOrder=false;this_.loadingOrder=false;console.log('Save Order',e,this);this_.$router.push('/positions')});
      }
    },
    del(){
      if(!this.loadingDel){
        let  this_=this;
        this_.loadingDel=true;
        this.$store.dispatch('EDIT',{all:[
            {action:'delete',page:'positions',data:[this.item_id]},
          ],}).then((e)=>{this_.loadingDel=false;console.log('Deleted',e,this);this_.$router.push('/positions')});
      }
    },
    save(){
      if(!this.loading){
        let  this_=this;
        this_.loading=true;
        this.$store.dispatch('EDIT',{all:[
            {action:'save',page:'positions',data:[
                {
                  id:               this.item_id,

                  name:             this.name,
                  alternative:      this.alternative,
                  active:           this.active,
                },
              ]
            },
          ],}).then((e)=>{
            this_.loading=false;
            console.log('Saved',e,this);
            this_.item_sel=this_.purchase.machinery.find((v)=>v.id-0===this_.item_id-0);
          });
      }
    },
    selectPosition(id){
      let u;
      if(id!==undefined || id!=='new'){
        u=this.purchase.positions.find((v)=>v.id-0===id-0);
        this.item_sel = u;
      }
      if(u!==undefined || id==='new'){
        this.name=u.name;
        this.alternative=u.alternative;
        this.active=u.active;
        this.item_id=u.id;
      }else{
        this.name='';
        this.alternative='';
        this.active=false;
        this.item_id='new';
      }
      if(id!==undefined && this.$route.path!=='/positions/'+id){
        this.$router.push('/positions/'+id);
      }
      if(id===undefined && this.$route.path!=='/positions/new'){
        this.$router.push('/positions/new');
      }
    },
  },
}
</script>


<style lang="stylus">
.left_tbl{
  &>tr>td,&>tbody>tr>td{.DragHand{width:20px;height:1.56rem;display:inline-block;vertical-align:middle;opacity:0;}}
  &>tr:hover>td,&>tbody>tr:hover>td{.DragHand{opacity:1;}}
  th{text-align:center;padding:3px 6px;
    svg{width:20px;height:20px;}
  }
  &>tr>td,&>tbody>tr>td{padding:3px 6px;cursor:pointer;}
  &>tr:hover>td,&>tbody>tr:hover>td{background:#eee;}
  &>tr.tr_sel>td,&>tbody>tr.tr_sel>td{background:#FFEBA3;}
}

.sets{padding:12px;
  label{display:block;padding:6px 0;}
  input:not([type="checkbox"]){display:block;width:100%;max-width:300px;}
}
</style>
