<template>
  <Layouts2side lvl1="/users" width="40%" height="70%">
    <button class="btn-add" @click="selectUser()">
      <span class="btn-icon"><svg><use xlink:href="#icon-plus"></use></svg></span>
      <span class="btn-text">Add User</span>
    </button>
    <button class="btn-add" @click="SelectGroup()">
      <span class="btn-icon"><svg><use xlink:href="#icon-plus"></use></svg></span>
      <span class="btn-text">Add Access Group</span>
    </button>
    <draggable
        v-model="purchase.access_groups"
        tag="div"
        draggable=".AccessGroup_bl"
        handle=".AccessGroup_drag"
        ghost-class="AccessGroup_ghost"
        v-bind="{
                        animation:600,
                        group: 'description',
                        // onChange:function(){$emit('update');},
                        // onEnd:function(){$emit('update');},
                      }"
        @start="drag = true;"
        @end="drag = false;"
        :disabled="false"
    >
      <transition-group type="transition" :name="!drag ? 'flip-list' : null">
        <div v-for="e in purchase.access_groups" :key="e.id" class="AccessGroup_bl">
          <div class="AccessGroup_bl-title"
               :class="{
                  ag_sel: e.id==$route.params.ag
                }"
          >
            <span class="AccessGroup_drag DragHand"></span>
            <span class="AccessGroup_name ">{{ e.name }}</span>
            <span class="AccessGroup_sets ">
              <span class="btn-icon" @click="SelectGroup(e.id)" title="Edit Access Group"><svg><use xlink:href="#icon-edit"></use></svg></span>
            </span>
          </div>
          <table cellspacing="0">
            <!--            <tr><th></th><th>Name</th><th>E-mail</th></tr>-->
            <tr v-for="u in purchase.users.filter((z)=>z.group_id==e.id)" :key="u.id" class="user" @click="selectUser(u.id)"
                :class="{
                  user_sel: u.id==$route.params.id
                }"
            >
<!--              <td><span class="btn-icon" @click="EditGroup(u.id)" title="Edit User"><svg><use xlink:href="#icon-edit"></use></svg></span></td>-->
              <td style="width:200px;" title="Login">{{u.username}} ({{u.name}})</td>
<!--              <td title="E-mail">{{u.email}}</td>-->
              <td>{{u.logins}} (last {{datetimePrint(u.last_login)}})</td>
            </tr>
          </table>
        </div>
      </transition-group>
    </draggable>

    <template #wind>
<!--      id:1,-->
      <div class="user_sets" v-if="$route.params.id!==undefined">
        <div class="flex">
          <div class="flex-sp"><Btn color="yellow" size="sm" :disabled="userChanged">Save</Btn></div>
          <div class="flex-sp" v-if="logins!==null">Logins:<br>{{logins}}</div>
          <div class="flex-sp" v-if="last_login!==null">Last login:<br>{{lastLoginPrint}}</div>
        </div>
        <div class="flex flex-top">
          <div class="user_sets__left">
            <label>
              <div class="label_top">Name:</div>
              <input class="" v-model="name"/>
            </label>
            <label>
              <div class="label_top">Login:</div>
              <input class="" v-model="username"/>
            </label>
            <label>
              <div class="label_top">E-mail:</div>
              <input class="" v-model="email"/>
            </label>


            <label>
              <div class="label_top">Password:</div>
              <input class="" type="password" v-model="password1"/>
    <!--          <div class="flex"><input class="" type="password" v-model="password1"/><input class="" type="password" v-model="password2"/></div>-->
            </label>
            <label>
              <div class="label_top">Token:</div>
              <input class="" v-model="appkey"/>
            </label>
            <label>
              <div class="label_top">Access group:</div>
              <select class="" v-model="group_id">
                <option v-for="e in purchase.access_groups" :key="e.id" :value="e.id">{{e.name}}</option>
              </select>
            </label>
            <label>
              <div class="label_top">Employee:</div>
              <select class="" v-model="employee_id">
                <option :value="null">---</option>
                <option v-for="e in purchase.employees" :key="e.id" :value="e.id">{{e.name}} {{e.surname}}</option>
              </select>
            </label>
          </div>
          <div class="flex-sp" style="padding:6px 0">
            <div><b>Vessels</b></div>
            <label v-for="e in purchase.vessels" :key="e.id" ><input type="checkbox" v-model="vessels" :value="e.id"/> {{e.name}}</label>
          </div>
        </div>
      </div>


      <div class="ag_sets" v-if="$route.params.ag!==undefined">
        <div class="flex">
          <div class="flex-sp"><Btn color="yellow" size="sm" :disabled="agChanged">Save</Btn></div>
        </div>

        <label>
          <div class="label_top">Name:</div>
          <input class="" v-model="ag_name"/>
        </label>
        <div><b>Rights</b></div>
        <label v-for="e in purchase.roles" :key="e.id" ><input type="checkbox" v-model="roles" :value="e.id"/> {{e.description}}</label>
      </div>

    </template>
  </Layouts2side>
</template>

<script>

import { mapState/*, mapActions*/ } from 'vuex';
import {VueDraggableNext} from 'vue-draggable-next'
import Layouts2side from "@/views/Layouts/Layouts2side";
import Btn from "@/components/ui/Btn";
// import SelectDot from "@/components/ui/SelectDot";
// import Btn from "@/components/ui/Btn"; // vue 3

export default {
  name: 'Users',
  components: {
    Btn,
    Layouts2side,
    draggable:VueDraggableNext,
  },
  mounted() {
    if(this.$route.params.id!==undefined){
      this.selectUser(this.$route.params.id);
    }
    if(this.$route.params.ag!==undefined){
      this.SelectGroup(this.$route.params.ag);
    }
  },
  data:()=>({
    drag:false,
    user_id:'',
    email:"",
    username:"",
    name:"",
    passwordChanged:false,
    password1:1,
    password2:1,
    appkey:"",
    group_id:0,
    logins:null,
    last_login:null,
    employee_id:null,
    vessels:[],

    user_sel:{},
    ag_sel:{},

    ag_id: 0,
    ag_name:"",
    roles:[],
  }),
  computed: {
    ...mapState(['purchase']),
    lastLoginPrint(){
      return this.datetimePrint(this.last_login)
    },
    userChanged(){
      return this.user_id==='new'?false:(
          ( this.user_sel.email===this.email ) &&
          ( this.user_sel.username===this.username ) &&
          ( this.user_sel.name===this.name ) &&
          ( this.password1==='' ) &&
          ( this.user_sel.appkey===this.appkey ) &&
          ( this.user_sel.group_id===this.group_id ) &&
          ( this.user_sel.employee_id===this.employee_id ) &&
          ( this.listArr(this.user_sel.vessels).join(',') === this.listArr(this.vessels).join(',') )
      );
    },
    agChanged(){
      return this.ag_id==='new'?false:(
          ( this.ag_sel.name===this.ag_name ) &&
          ( this.listArr(this.ag_sel.roles).join(',') === this.listArr(this.roles).join(',') )
      );
    },
  },
  methods:{
    listArr(arr){
      // console.log('listArr(arr)',arr);
      return !arr?[]:(Object.keys(arr).map((key) => {
        return arr[key]
      }).sort());

    },
    datetimePrint(time){
      let dt = (new Date(time * 1000));
      return dt.getFullYear()+'-'+dt.getMonth()+'-'+dt.getDate()+' '+dt.getHours()+':'+dt.getMinutes();
    },
    selectUser(id){
      let u;
      if(id!==undefined){
        u=this.purchase.users.find((u)=>u.id-0===id-0);
        this.user_sel = u;
      }
      if(u!==undefined){
        this.email=u.email;
        this.username=u.username;
        this.name=u.name;
        this.passwordChanged=false;
        this.password1='';
        this.password2='';
        this.appkey=u.appkey;
        this.group_id=u.group_id;
        this.employee_id=u.employee_id;
        this.logins=u.logins;
        this.last_login=u.last_login;
        this.vessels=u.vessels;
        this.user_id=u.id;
      }else{
        this.email='';
        this.username='';
        this.name='';
        this.passwordChanged=false;
        this.password1='';
        this.password2='';
        this.appkey='';
        this.group_id='';
        this.employee_id='';
        this.logins=null;
        this.last_login=null;
        this.vessels=[];
        this.user_id='new';
      }
      if(id!==undefined && this.$route.path!=='/users/'+id){
        this.$router.push('/users/'+id);
      }
      if(id===undefined && this.$route.path!=='/users/new'){
        this.$router.push('/users/new');
      }
    },
    addGroup(){

    },
    SelectGroup(id) {
      let d;
      if(id!==undefined){
        d = this.purchase.access_groups.find((k) => k.id - 0 === id - 0);
        this.ag_sel=d;
      }
      if(d!==undefined){
        this.ag_name=d.name;
        this.roles=d.roles;
        this.ag_id=d.id;
      }else{
        this.ag_name='';
        this.roles=[];
        this.ag_id='new';
      }
      if(id!==undefined && this.$route.path!=='/users/ag/'+id){
        this.$router.push('/users/ag/'+id);
      }
      if(id===undefined && this.$route.path!=='/users/ag/new'){
        this.$router.push('/users/ag/new');
      }
    },
  },
}
</script>


<style lang="stylus">
.AccessGroup_bl{margin-top:24px;}
.AccessGroup_drag{width:10px;height:1.56rem;display:inline-block;vertical-align:middle;opacity:0;}
.AccessGroup_sets{margin:0px 0 2px 6px;vertical-align:middle;display:none;}
.AccessGroup_bl-title{
  .btn-icon{width:20px;height:20px;display:inline-block;vertical-align:middle;cursor:pointer;
    svg{width:20px;height:20px;fill:#007bff;}
  }
  &:hover{
    .AccessGroup_sets{display:inline-block;}
    .AccessGroup_drag{opacity:1;}
  }
}
.AccessGroup_name{display:inline-block;margin-left:6px;vertical-align:middle;font-size:1.3em;}
.user{
  td:nth-child(1){padding-left:18px;}
  .btn-icon{width:20px;height:20px;display:inline-block;vertical-align:middle;cursor:pointer;opacity:0.2;margin: -2px 0 2px 0;
    svg{width:20px;height:20px;fill: #007bff;}
  }
  &:hover{
    td{background:#eee;}
    .btn-icon{opacity:1;}
  }
  &_sel{background:#FFEBA3;}
}

.user_sets{padding:12px;
  label{display:block;padding:6px 0;}
  input:not([type="checkbox"]){display:block;width:100%;max-width:340px;}
  select{display:block;width:100%;max-width:300px;}
  &__left{margin-right:12px;max-width:300px;}
}

.AccessGroup_bl td{padding:3px 12px;}
.ag_sets{
  padding:12px;
  label{display:block;padding:2px 0;}
}
.ag_sel{background:#FFEBA3;}
</style>
