<template>
  <div class="home">
    <h1>Manuals</h1>
    <br>
    <br>
    <ui-table :columns="columns"
              :rows="rows"
    />
  </div>
</template>

<script>
import UiTable from "@/views/UiTable";
export default {
  name: "Manuals",
  components: {UiTable},
  data(){
    return {
      rows:[
        {id:1,no:'2',txt:'text1',in:1,out:6},
        {id:2,no:'5',txt:'text2',in:2,out:7},
        {id:3,no:'7',txt:'text3',in:3,out:8},
        {id:4,no:'5',txt:'text4',in:4,out:9},
        {id:5,no:'4',txt:'text5',in:5,out:10},
        {id:6,no:'1',txt:'text6',in:6,out:11},
      ],
      columns:[
        {id:'no', title:'No',        type:'text', width:'30px'},
        {id:'txt',title:'Text field',type:'text', flex: 1},
        {id:'zzz',title:'Text field', children:[
            {id:'in',title:'IINNN', type:'text', width:'60px'},
            {id:'out',title:'OOOUT',type:'text', width:'60px'},
          ]},
        {id:'zzz1',title:'Text field', children:[
            {id:'in2',title:'IINNN', type:'text', width:'60px'},
            {id:'out2',title:'OOOUT',type:'text', width:'60px'},
          ]},
      ]
    }
  }
}
</script>

<style scoped>

</style>
