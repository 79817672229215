<template>
  <div class="home">
    <h1>ITF Wage Scale & COE</h1>

  </div>
</template>

<script>


export default {
  name: 'ITF Wage Scale & COE',
  components: {},
  // components: {}
}
</script>
