<template>
  <div class="home">
    <Dashboard/>
    <h1>Home</h1>
    <p>The main page may be called the "Dashboard" and this page may display important events or summaries from other
        sections.<br/>
        For example, a list of documents that will expire within the next month can be displayed.</p>
  </div>
</template>

<script>

import Dashboard from "@/views/Dashboard";
export default {
  name: 'Home',
  components: {Dashboard},
  // components: {}
}
</script>
