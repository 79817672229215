<template>
  <span class="SelectDot" ref="sel">
    <span class="SelectDot__dot"
          :style="{
            backgroundColor: options[modelValue].color
          }"
    ></span>
    <DropDown hover-ref="sel"
              :width="width"
    >
      <div class="SelectDot__header" v-if="$slots.header"><slot name="header"></slot></div>
      <div :style="styleDD">
        <template v-for="(item,index) in options" :key="'z'+item.id">
          <Tag :tag="itemTag"
               @click="$emit('update:modelValue', item.id)"
               @mouseover="$event.currentTarget.classList.add(dropdownClassItemHover)"
               @mouseout="$event.currentTarget.classList.remove(dropdownClassItemHover)"
               class="SelectDot__item"
               :class="modelValue===item.id? dropdownClassSel : ''"
          >
            <slot :item="item"
                  :index="index"
                  :line="index+1"
                  :selected="!modelValue ? false : (modelValue===item.id)"
                  name="dropdown"
            >
              <span class="SelectDot__dot" :style="{backgroundColor: item.color}"></span> {{item.name}}
            </slot>
          </Tag>
        </template>
      </div>
    </DropDown>
  </span>
</template>

<script>
import DropDown from './DropDown.vue'
import Tag from 'UI/Tag'

export default {
  name: "SelectDot",
  props:{
    options:{               type:Object, required:true,},
    modelValue:{            type:Number, required:true,},
    dropdownClassItemHover:{type:String, default:'SelectDot__select_dropdown_item_hover', },
    dropdownClassSel:{      type:String, default:'SelectDot__select_dropdown_sel', },
    itemTag:{               type:String, default:'div',},
    width:{                 type:Number, },
  },
  components:{
    DropDown,
    Tag
  },
  methods:{
    getRefs(ref){return this.$refs[ref];},
  },
  computed:{
    styleDD(){
      return !this.width?'':{width:this.width+'px'}
    }
  }
}
</script>

<style>
.SelectDot{display:inline-block;position: relative;padding:3px;font-size: 0px;vertical-align: middle;margin:-2px 0 2px 0}
.SelectDot__dot{display:inline-block;width:12px;height:12px;background:#ccc;border-radius: 100%;}
.SelectDot__header{padding:6px; text-align: center; font-weight:bold;}
.SelectDot__item{padding:6px 12px;}
.SelectDot__item .SelectDot__dot{margin-right:6px;}
.SelectDot__select_dropdown_item_hover{background: #eee}
.SelectDot__select_dropdown_sel{background: #FDEDAF}
</style>
