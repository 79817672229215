<template>
	<button class="Btn"
          :class="(color?(' Btn-'+color):'')+(outline?' Btn-outline':'')+(size?(' Btn-size-'+size):'')+(block?' Btn-block':'')+(disabled?' disabled':'')"
          @click.prevent.stop="$emit('click', $event)"
          :disabled="disabled"
  >
		<span class="Btn-icon" v-if="icon"><svg><use xmlns:xlink="http://www.w3.org/1999/xlink" :xlink:href="'#icon-'+icon"></use></svg></span>
		<span class="Btn-text"><slot></slot></span>
		<span class="Btn-icon2" v-if="icon2"><svg><use xmlns:xlink="http://www.w3.org/1999/xlink" :xlink:href="'#icon-'+icon2"></use></svg></span>
	</button>
</template>

<script>
export default {
  name: "Btn",
  props:{
    icon: {     type: String,   default: ''    },
    icon2: {    type: String,   default: ''    },
    color: {    type: String,   default: ''    },
    outline: {  type: Boolean,  default: false },
    size: {     type: String,   default: ''    },
    block: {    type: Boolean,  default: false },
    disabled: { type: Boolean,  default: false },
  },
}
</script>

<style scoped></style>
<style lang="stylus">
	// gray, secondary
	.Btn{
		margin:0;
		display: inline-block;
		font-weight: 400;
		text-align: center;
		white-space: nowrap;
		vertical-align: middle;
		user-select: none;
		padding: .375rem .75rem;
		font-size: 1rem;
		line-height: 1.5;
		border-radius: .25rem;
		-webkit-appearance: button;
		transition: color .15s ease-in-out,background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
		outline:none;
		color:#fff;background-color:#6c757d;border: 1px solid #6c757d;
		&:not(:disabled):not(.disabled) {cursor: pointer;}
		svg{width:1.5rem;height:1.5rem;fill:#fff;transition: fill .15s ease-in-out;}
		&:hover{text-decoration: none;color:#fff;background-color:#5a6268;border-color: #545b62;svg{fill:#fff;}}
		&:not(:disabled):not(.disabled):active{color:#fff;background-color:#545b62;border-color: #4e555b;svg{fill:#fff;}}
		&.focus,&:focus{box-shadow: 0 0 0 0.2rem rgba(108,117,125,.5);text-decoration: none;svg{fill:#fff;}}
		&.disabled,&:disabled{color: #eee; background-color: #6c757d; border-color: #6c757d;opacity: .65;}

		&.Btn-outline{background-color:transparent;color: #6c757d;svg{fill:#6c757d;}
			&:hover{color: #fff; background-color: #6c757d; border-color: #6c757d;svg{fill:#fff;}}
		}
		&.Btn-size-lg{padding: .5rem 1rem; font-size: 1.25rem; line-height: 1.5; border-radius: .3rem;}
		&.Btn-size-sm{padding: .25rem .5rem; font-size: .875rem; line-height: 1.5; border-radius: .2rem;}
		&.Btn-block{display:block;width: 100%;}
	}

	.Btn-text{display:inline-block;vertical-align:middle;}
	.Btn-icon,.Btn-icon2{display:inline-block;vertical-align: middle;}
	.Btn-icon{margin:-.05rem .375rem  .05rem  -.375rem;}
	.Btn-icon2{margin:-.05rem -.375rem .05rem .375rem;}

	.Btn-icon,.Btn-icon2{width:1.5rem;height:1.5rem;}


	.Btn.Btn-green,.Btn.Btn-success{ color: #fff; background-color: #28a745; border-color: #28a745;
		/*svg{fill:#fff}*/
		&:hover{color: #fff; background-color: #218838; border-color: #1e7e34; /*svg{fill:#fff;}*/}
		&:not(:disabled):not(.disabled):active{color: #fff; background-color: #1e7e34; border-color: #1c7430;/*svg{fill:#fff;}*/}
		&.focus,&:focus{box-shadow: 0 0 0 0.2rem rgba(40,167,69,.5);/*svg{fill:#fff;}*/}
		&.disabled,&:disabled{color: #fff; background-color: #28a745; border-color: #28a745;}
		&.Btn-outline{color: #28a745; background-color: transparent; background-image: none; border-color: #28a745;svg{fill:#28a745;}
			&:hover{color: #fff; background-color: #28a745; border-color: #28a745;svg{fill:#fff;}}
		}
	}
	.Btn.Btn-blue,.Btn.Btn-primary{ color: #fff; background-color: #007bff; border-color: #007bff;
		/*svg{fill: #fff}*/
		&:hover{color: #fff; background-color: #0069d9; border-color: #0062cc; /*svg{fill:#fff;}*/}
		&:not(:disabled):not(.disabled):active{color: #fff; background-color: #0062cc; border-color: #005cbf;/*svg{fill:#fff;}*/}
		&.focus,&:focus{box-shadow: 0 0 0 0.2rem rgba(0,123,255,.5);/*svg{fill:#fff;}*/}
		&.disabled,&:disabled{color: #fff; background-color: #007bff; border-color: #007bff;}
		&.Btn-outline{color: #007bff; background-color: transparent; background-image: none; border-color: #007bff;svg{fill:#007bff;}
			&:hover{color: #fff; background-color: #007bff; border-color: #007bff;svg{fill:#fff;}}
		}
	}
	.Btn.Btn-red,.Btn.Btn-danger{ color: #fff; background-color: #dc3545; border-color: #dc3545;
		/*svg{fill: #fff}*/
		&:hover{color: #fff; background-color: #c82333; border-color: #bd2130; /*svg{fill:#fff;}*/}
		&:not(:disabled):not(.disabled):active{color: #fff; background-color: #bd2130; border-color: #b21f2d;/*svg{fill:#fff;}*/}
		&.focus,&:focus{box-shadow: 0 0 0 0.2rem rgba(220,53,69,.5);/*svg{fill:#fff;}*/}
		&.disabled,&:disabled{color: #fff; background-color: #dc3545; border-color: #dc3545;}
		&.Btn-outline{color: #dc3545; background-color: transparent; background-image: none; border-color: #dc3545;svg{fill:#dc3545;}
			&:hover{color: #fff; background-color: #dc3545; border-color: #dc3545;svg{fill:#fff;}}
		}
	}
	.Btn.Btn-yellow,.Btn.Btn-warning{ color: #212529; background-color: #ffc107; border-color: #ffc107;
		svg{fill: #212529}
		&:hover{color: #212529; background-color: #e0a800; border-color: #d39e00; svg{fill:#212529;}}
		&:not(:disabled):not(.disabled):active{color: #212529; background-color: #d39e00; border-color: #c69500;/*svg{fill:#fff;}*/}
		&.focus,&:focus{box-shadow: 0 0 0 0.2rem rgba(255,193,7,.5);/*svg{fill:#fff;}*/}
		&.disabled,&:disabled{color: #212529; background-color: #ffc107; border-color: #ffc107;}
		&.Btn-outline{color: #ffc107; background-color: transparent; background-image: none; border-color: #ffc107;svg{fill:#ffc107;}
			&:hover{color: #212529; background-color: #ffc107; border-color: #ffc107;svg{fill:#212529;}}
		}
	}
	.Btn.Btn-blue2,.Btn.Btn-info{ color: #fff; background-color: #17a2b8; border-color: #17a2b8;
		/*svg{fill: #fff}*/
		&:hover{color: #fff; background-color: #138496; border-color: #117a8b; /*svg{fill:#fff;}*/}
		&:not(:disabled):not(.disabled):active{color: #fff; background-color: #117a8b; border-color: #10707f;/*svg{fill:#fff;}*/}
		&.focus,&:focus{box-shadow: 0 0 0 0.2rem rgba(23,162,184,.5);/*svg{fill:#fff;}*/}
		&.disabled,&:disabled{color: #fff; background-color: #17a2b8; border-color: #17a2b8;}
		&.Btn-outline{color: #17a2b8; background-color: transparent; background-image: none; border-color: #17a2b8;svg{fill:#17a2b8;}
			&:hover{color: #fff; background-color: #17a2b8; border-color: #17a2b8;svg{fill:#fff;}}
		}
	}
	.Btn.Btn-light{ color: #212529; background-color: #f8f9fa; border-color: #f8f9fa;
		svg{fill: #212529}
		&:hover{color: #212529; background-color: #e2e6ea; border-color: #dae0e5; svg{fill:#212529;}}
		&:not(:disabled):not(.disabled):active{color: #212529; background-color: #dae0e5; border-color: #d3d9df;svg{fill:#212529;}}
		&.focus,&:focus{box-shadow: 0 0 0 0.2rem rgba(218,218,218,.5);svg{fill:#212529;}} //rgba(248,249,250,.5)
		&.disabled,&:disabled{color: #212529; background-color: #f8f9fa; border-color: #f8f9fa;svg{fill:#212529;}}
		&.Btn-outline{color: #797e82; background-color: transparent; background-image: none; border-color: #797e82;svg{fill:#797e82;}
			&:hover{color: #797e82; background-color: #e2e6ea; border-color: #212529;svg{fill:#797e82;}}
		}
	}
	.Btn.Btn-black,.Btn.Btn-dark{ color: #fff; background-color: #343a40; border-color: #343a40;
		/*svg{fill: #fff}*/
		&:hover{color: #fff; background-color: #23272b; border-color: #1d2124; /*svg{fill:#fff;}*/}
		&:not(:disabled):not(.disabled):active{color: #fff; background-color: #1d2124; border-color: #171a1d;/*svg{fill:#fff;}*/}
		&.focus,&:focus{box-shadow: 0 0 0 0.2rem rgba(52,58,64,.5);/*svg{fill:#fff;}*/}
		&.disabled,&:disabled{color: #fff; background-color: #343a40; border-color: #343a40;}
		&.Btn-outline{color: #343a40; background-color: transparent; background-image: none; border-color: #343a40;svg{fill:#343a40;}
			&:hover{color: #fff; background-color: #343a40; border-color: #343a40;svg{fill:#fff;}}
		}
	}
	.Btn.Btn-link{ font-weight: 400; color: #007bff; background-color: transparent;border-color: transparent;
		svg{fill: #007bff}
		&:hover{color: #0056b3;  background-color: transparent; border-color: transparent;.Btn-text{text-decoration: underline;} svg{fill:#0056b3;}}
		&:not(:disabled):not(.disabled):active{background-image: none; color: #007bff; background-color: transparent;border-color: transparent;.Btn-text{text-decoration: underline;} svg{fill:#007bff;}}
		&.focus,&:focus{background-color: transparent; box-shadow: none;border-color: transparent; .Btn-text{text-decoration: underline;}  svg{fill:#007bff;}}
		&.disabled,&:disabled{color: #6c757d;background-color: transparent; box-shadow: none;border-color: transparent; svg{fill:#6c757d;}}
		&.Btn-outline{color: #007bff; background-color: transparent; background-image: none; border-color: transparent; svg{fill:#007bff;}
			.Btn-text{text-decoration: none;position:relative;}
			.Btn-text:after{content:'';display:block;border-bottom:1px dashed #007bff;position:absolute;bottom:0.3rem;left:0.1rem;right:0.1rem;}
			&:hover{color: #0056b3; background-color: transparent; border-color: transparent;svg{fill:#0056b3;}
				.Btn-text:after{border-bottom:1px dotted #0056b3;}
			}
		}
	}
  .Btn+.Btn{margin-left:12px;}
  .Btn.disabled,.Btn:disabled{background-color:#eee!important;border-color:#eee!important;color:#aaa!important}
</style>
