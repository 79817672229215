<template>
  <div class="home">
    <h1>System verifications</h1>
  </div>
</template>

<script>
export default {
  name: "SystemVerifications"
}
</script>

<style scoped>

</style>
